export function SET_USERS (state, data) {
    state.users = data.users
}

export function SET_USER_STATUS (state, data) {
    state.users.data.forEach(userInList =>{
        if(data.changeUserStatus.id == userInList.id){
            userInList.status = data.changeUserStatus.status;
        }
    });
    
}