import Vue from 'vue'
import store from '@/store'
import graphqlClient from '@/plugins/apollo'
import {CHANGE_STATUS, CREATE_USERS, EDIT_USERS, FETCH_USERS, DELETE_USERS, GET_NOTIFICATIONS} from '@/services/graphql/users'

export async function fetchUsers (context, data) {
    return new Promise((resolve, reject) => {
        let _page = data ? data.page : 1
        graphqlClient.query({
            query: FETCH_USERS,
            variables: {
                page: _page
            }
        }).then(res => {
            context.commit('SET_USERS',res.data)
            resolve(res)
        }).catch(error => {
            reject(error)
        })
    })
}

export async function createUsers (context, user) {
    return new Promise((resolve, reject) => {
        graphqlClient.mutate({
            mutation: CREATE_USERS,
            variables: {
                name: user.name,
                last_name: user.lastName,
                email: user.email,
                password: user.repeatPassword,
                role: user.role,
                project_ids: user.userProjects,
                realstateagency_id: user.realstateagency_id.toString(),
            },
            update: (store,{data: {createUser}}) => {
                // const data = store.readQuery({query: FETCH_USERS, variables: {page: 1}})
                // data.users.data.unshift(createUser)
                // store.writeQuery({query: FETCH_USERS, data})
            },
        }).then(res => {
            resolve(res)
        }).catch(error => {
            reject(error)
        })
    })
}

export async function updateUsers (context, user) {
    return new Promise((resolve, reject) => {
        graphqlClient.mutate({
            mutation: EDIT_USERS,
            variables: {
                id: user.id,
                name: user.name,
                last_name: user.lastName,
                email: user.email,
                status: user.state,
                role: user.role,
                projects_id: user.userProjects,
                realstateagency_id: user.realstateagency_id,
                permissions: JSON.stringify(user.permissions).replace(/"/g, "|")
            },
            update: (store,{data: {EDIT_USERS}}) => {
                // const data = store.readQuery({query: FETCH_USERS,
                // variables: { page: 1 }})
                // context.commit('SET_USERS',data)
            },
        }).then(res => {
            resolve(res)
        }).catch(error => {
            reject(error)
        })
    })
}

export async function deleteUsers (context, user) {
    return new Promise((resolve, reject) => {
        graphqlClient.mutate({
            mutation: DELETE_USERS,
            variables: {
                id: user.id
            },
            update: (store,{data: {DELETE_USERS}}) => {
                const data = store.readQuery({query: FETCH_USERS,
                variables: { page: user.page }})
                data.users.data.forEach(function(value, index, object) {
                    if(value.id === user.id) {
                        object.splice(index, 1)
                    }
                })
                context.commit('SET_USERS',data)
            },
        }).then(res => {
            resolve(res)
        }).catch(error => {
            reject(error)
        })
    })
}

export async function changeStatus (context, user) { 
    return new Promise((resolve,reject) => {
        graphqlClient.mutate({
            mutation: CHANGE_STATUS,
            variables: {
                status: user.status,
                userid: user.id,
            },
            update: (store,{data: {CHANGE_STATUS}}) => {
                // const data = store.readQuery({query: FETCH_USERS,
                // variables: { page: 1 }})
                // context.commit('SET_USERS',data)
            },
        }).then(res => {
            context.commit('SET_USER_STATUS',res.data)
            resolve(res)
        }).catch(error => {
            reject(error)
        })
    })
}

export async function exportUsers (context) {
    return new Promise((resolve, reject) => {
        Vue.axios({
            method: 'GET',
            responseType: 'blob',
            url: 'api/excelUsers',
            headers: {'Authorization': `Bearer ${store.state.auth.accessToken}`},
        }).then(res => {
            let blob = new Blob([res.data], {type: 'application/vnd.ms-excel'})
            let url = window.URL.createObjectURL(blob)
            let a = document.createElement('a')
            a.href = url
            a.download = 'Usuarios.xlsx'
            a.click()
            window.URL.revokeObjectURL(url)
            resolve(res)
        }).catch(error => {
            reject(error)
        })
    })
}

export async function getNotifications (context, data) {
    return new Promise((resolve, reject) => {
        let _page = data ? data.page : 1
        let _limit = data ? data.limit : 5
        graphqlClient.query({
            query: GET_NOTIFICATIONS,
            variables: {
                page: _page,
                limit: _limit,
                user_id: data.user_id,
            }
        }).then(res => {
            resolve(res)
        }).catch(error => {
            reject(error)
        })
    })
}