import gql from 'graphql-tag'

export const FETCH_PROMISES_ORDERS = gql`
    query fetchPromisesOrders(
        $project_id: Int,
        $status: String,
        $page: Int
    ){
        promiseorders (status: $status, project_id: $project_id, page: $page){
            data{
                id
                status
                created_at
                promiserequest{
                    id
                    amount_paid
                    uf_value
                    status
                    order_number
                    datetime_transaction
                    gateway_reference
                    signature
                    quotation{
                        id
                        project{
                            id
                            name
                        }
                        customer{
                            id
                            name
                            last_name
                            rut
                            rut_dv
                            email
                            phone
                            status
                        }
                        propertyquotation{
                            id
                            price
                            property{
                                id
                                identifier
                                model
                                price
                                floor
                                bedrooms
                                bathrooms
                                tower
                                total_area
                                municipal_area
                                built_area
                                terrace_area
                            }
                            warehouse{
                                id
                                identifier
                                price
                                floor
                                tower
                                total_area
                                municipal_area
                                built_area
                            }
                            parkinglot{
                                id
                                identifier
                                price
                                floor
                                tower
                                total_area
                                municipal_area
                                built_area
                            }
                        }
                    }
                }
            }
            total
            per_page
            current_page
            from
            to
            last_page
            has_more_pages
        }
    }
`

export const EDIT_PROMISE_ORDERS = gql`
    mutation EditPromiseOrders($promiseorder_id: Int!, $status: String!) {
        editPromiseOrder(promiseorder_id: $promiseorder_id, status: $status) {
            id
            status
        }
    }
`

export const FETCH_PROMISES_REQUESTS = gql`
    query fetchPromisesRequests(
        $project_id: Int,
        $status: String,
        $page: Int
    ){
        promiserequests (status: $status, project_id: $project_id, page: $page){
            data{
                id
                status
                amount_paid
                uf_value
                uf_value
                order_number
                datetime_transaction
                gateway_reference
                signature
                created_at
                payments{
                    id
                    transaction_amount
                    token
                    email
                    status
                    promise_request_id
                    booking_request_id
                    flow_transfer_date
                    flow_balance
                    flow_taxes
                    flow_fee
                    flow_currency
                    flow_amount
                    flow_conversion_rate
                    flow_conversion_date
                    flow_media
                    flow_date
                    flow_payer
                    flow_commerce_order
                    flow_order
                    created_at
                }
                quotation{
                    id
                    project{
                        id
                        name
                    }
                    customer{
                        id
                        name
                        last_name
                        rut
                        rut_dv
                        email
                        phone
                        status
                    }
                    propertyquotation{
                        id
                        price
                        property{
                            id
                            identifier
                            model
                            price
                            floor
                            bedrooms
                            bathrooms
                            tower
                            total_area
                            municipal_area
                            built_area
                            terrace_area
                        }
                        warehouse{
                            id
                            identifier
                            price
                            floor
                            tower
                            total_area
                            municipal_area
                            built_area
                        }
                        parkinglot{
                            id
                            identifier
                            price
                            floor
                            tower
                            total_area
                            municipal_area
                            built_area
                        }
                    }
                }
            }
            total
            per_page
            current_page
            from
            to
            last_page
            has_more_pages
        }
    }
`

export const EDIT_PROMISE_REQUESTS = gql`
    mutation EditPromiseRequests($promiserequest_id: Int!, $status: String!) {
        editPromiseRequest(promiserequest_id: $promiserequest_id, status: $status) {
            id
            status
        }
    }
`

export const EXPORT_PROMISE_ORDER_FILE = gql`
    mutation exportPromiseOrder( 
        $project_id: Int,
        $status: String,
        ) {
        requestPromiseOrderFile( 
            project_id: $project_id,
            status: $status,
        ) {
            id
        }
    }
`

export const EXPORT_PROMISE_REQUEST_FILE = gql`
    mutation exportPromiseRequest( 
        $project_id: Int,
        $status: String,
        ) {
        requestPromiseRequestFile( 
            project_id: $project_id,
            status: $status,
        ) {
            id
        }
    }
`