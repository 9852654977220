import Vue from 'vue'
import FeatherIcon from '@core/components/feather-icon/FeatherIcon.vue'
import vSelect from 'vue-select'
import {
    BOverlay,
    BRow,
    BCol,
    BLink,
    BFormGroup,
    BFormInput,
    BInputGroupAppend,
    BInputGroup,
    BFormCheckbox,
    BCardText,
    BCardTitle,
    BImg,
    BForm,
    BButton,
    BCardGroup,
    BCard,
    BTable,
    BBadge,
    BDropdown,
    BDropdownItem,
    BPagination,
    BMedia,
    BMediaAside,
    BMediaBody,
    BAvatar,
    BFormSelect,
    BIcon,
    BFormFile,
    BFormSelectOption,
    BFormDatepicker,
    BTabs,
    BTab,
    BFormTextarea,
    BButtonGroup,
    BCardBody,
    BSidebar
} from 'bootstrap-vue'

Vue.component(FeatherIcon.name, FeatherIcon)
Vue.component('v-select',vSelect)
Vue.component('b-overlay', BOverlay)
Vue.component('b-row', BRow)
Vue.component('b-col', BCol)
Vue.component('b-link', BLink)
Vue.component('b-form-group', BFormGroup)
Vue.component('b-form-input', BFormInput)
Vue.component('b-input-group-append', BInputGroupAppend)
Vue.component('b-input-group', BInputGroup)
Vue.component('b-form-checkbox', BFormCheckbox)
Vue.component('b-card-title', BCardTitle)
Vue.component('b-card-text', BCardText)
Vue.component('b-img', BImg)
Vue.component('b-form', BForm)
Vue.component('b-button', BButton)
Vue.component('b-card-group', BCardGroup)
Vue.component('b-card', BCard)
Vue.component('b-table', BTable)
Vue.component('b-badge', BBadge)
Vue.component('b-dropdown', BDropdown)
Vue.component('b-dropdown-item', BDropdownItem)
Vue.component('b-pagination', BPagination)
Vue.component('b-media', BMedia)
Vue.component('b-media-aside', BMediaAside)
Vue.component('b-media-body', BMediaBody)
Vue.component('b-avatar', BAvatar)
Vue.component('b-form-select', BFormSelect)
Vue.component('b-icon', BIcon)
Vue.component('b-form-file', BFormFile)
Vue.component('b-form-select-option', BFormSelectOption)
Vue.component('b-form-datepicker', BFormDatepicker)
Vue.component('b-tabs', BTabs)
Vue.component('b-tab', BTab)
Vue.component('b-form-textarea', BFormTextarea)
Vue.component('b-button-group', BButtonGroup)
Vue.component('b-card-body', BCardBody)
Vue.component('b-sidebar', BSidebar)