import Vue from 'vue'
import store from '@/store'

export async function graphicContactProject (context, filters) {
    return new Promise((resolve, reject) => {
        Vue.axios({
            method: 'POST',
            url: '/api/dashboard/contactsByProject',
            params: {
                project_id: filters.project,
                real_state_agency_id: filters.real_state_agency,
                type: filters.type,
                date_init: filters.start,
                date_finish: filters.end
            },
            headers: {'Authorization': `Bearer ${store.state.auth.accessToken}`},
        }).then(res => {
            resolve(res)
        }).catch(error => {
            reject(error)
        })
    })
}

export async function graphicContactDate (context, filters) {
    return new Promise((resolve, reject) => {
        Vue.axios({
            method: 'POST',
            url: '/api/dashboard/contactsByDate',
            params: {
                project_id: filters.project,
                real_state_agency_id: filters.real_state_agency,
                type: filters.type,
                date_init: filters.start,
                date_finish: filters.end
            },
            headers: {'Authorization': `Bearer ${store.state.auth.accessToken}`},
        }).then(res => {
            resolve(res)
        }).catch(error => {
            reject(error)
        })
    })
}


export async function graphicQuotationProject (context, filters) {
    return new Promise((resolve, reject) => {
        Vue.axios({
            method: 'POST',
            url: '/api/dashboard/quotationsByProject',
            params: {
                project_id: filters.project,
                real_state_agency_id: filters.real_state_agency,
                type: filters.type,
                date_init: filters.start,
                date_finish: filters.end
            },
            headers: {'Authorization': `Bearer ${store.state.auth.accessToken}`},
        }).then(res => {
            resolve(res)
        }).catch(error => {
            reject(error)
        })
    })
}

export async function graphicQuotationDate (context, filters) {
    return new Promise((resolve, reject) => {
        Vue.axios({
            method: 'POST',
            url: '/api/dashboard/quotationsByDate',
            params: {
                project_id: filters.project,
                real_state_agency_id: filters.real_state_agency,
                type: filters.type,
                date_init: filters.start,
                date_finish: filters.end
            },
            headers: {'Authorization': `Bearer ${store.state.auth.accessToken}`},
        }).then(res => {
            resolve(res)
        }).catch(error => {
            reject(error)
        })
    })
}

export async function graphicPromisesDate (context, filters) {
    return new Promise((resolve, reject) => {
        Vue.axios({
            method: 'POST',
            url: '/api/dashboard/promiseRequestByDate',
            params: {
                project_id: filters.project,
                real_state_agency_id: filters.real_state_agency,
                type: filters.type,
                date_init: filters.start,
                date_finish: filters.end
            },
            headers: {'Authorization': `Bearer ${store.state.auth.accessToken}`},
        }).then(res => {
            resolve(res)
        }).catch(error => {
            reject(error)
        })
    })
}

export async function graphicBookingsDate (context, filters) {
    return new Promise((resolve, reject) => {
        Vue.axios({
            method: 'POST',
            url: '/api/dashboard/bookingRequestByDate',
            params: {
                project_id: filters.project,
                real_state_agency_id: filters.real_state_agency,
                type: filters.type,
                date_init: filters.start,
                date_finish: filters.end
            },
            headers: {'Authorization': `Bearer ${store.state.auth.accessToken}`},
        }).then(res => {
            resolve(res)
        }).catch(error => {
            reject(error)
        })
    })
}

export async function graphicBusiness (context, filters) {
    return new Promise((resolve, reject) => {
        Vue.axios({
            method: 'POST',
            url: '/api/dashboard/businessByDate',
            params: {
                project_id: filters.project,
                real_state_agency_id: filters.real_state_agency,
                date_init: filters.start,
                date_finish: filters.end
            },
            headers: {'Authorization': `Bearer ${store.state.auth.accessToken}`},
        }).then(res => {
            resolve(res)
        }).catch(error => {
            reject(error)
        })
    })
}

export async function graphicSales (context, filters) {
    return new Promise((resolve, reject) => {
        Vue.axios({
            method: 'POST',
            url: '/api/dashboard/salesFunnelByProject',
            params: {
                project_id: filters.project,
                real_state_agency_id: filters.real_state_agency,
                date_init: filters.start,
                date_finish: filters.end
            },
            headers: {'Authorization': `Bearer ${store.state.auth.accessToken}`},
        }).then(res => {
            resolve(res)
        }).catch(error => {
            reject(error)
        })
    })
}