import graphqlClient from '@/plugins/apollo'
import { CREATE_COMMENTS, EDIT_COMMENTS, FETCH_COMMENTS } from '@/services/graphql/comments';

export async function fetchComments (context, filter) {
    if(filter != undefined){ 
        if(filter.project != "" && filter.project != null){
            var project = filter.project
        }

        var page = "";
        if(filter.page != ""){page = filter.page}
    }
    return new Promise((resolve, reject) => {
        var variables = {
            project_id: project,
            page: page
        };
        graphqlClient.query({
            query: FETCH_COMMENTS,
            variables: variables
        }).then(res => {
            context.commit('SET_COMMENTS',res.data)
            resolve(res)
        }).catch(error => {
            reject(error)
        })
    })
}

export async function createComments (context, comment) {
    return new Promise((resolve, reject) => {
        let data = { 
            name: comment.name,
            comment: comment.comment,
            project_id: comment.project
        };

        if(comment.logo){
            if(comment.logo instanceof File){
                data.media = comment.logo;
            }   
        }
        
        graphqlClient.mutate({
            mutation: CREATE_COMMENTS,
            context: {
                hasUpload: true // Important!
            },
            variables:data,
            update: (store,{data: {createComments}}) => {
            },
        }).then(res => {
            context.commit('ADD_ONE_COMMENTS',res.data);
            resolve(res)
        }).catch(error => {
            reject(error)
        })
    })
}
export async function updateComments (context, comment) {
    return new Promise((resolve, reject) => {
        let data = {
            comment_id: comment.id,
            name: comment.name,
            comment: comment.comment,
            project_id: comment.project
        };

        if(comment.logo){
            if(comment.logo instanceof File){
                data.media = comment.logo;
            }   
        }
        
        graphqlClient.mutate({
            mutation: EDIT_COMMENTS,
            variables: data,
            update: (store,{data: {EDIT_COMMENTS}}) => {
            },
        }).then(res => {
            context.commit('UPDATE_ONE_COMMENTS',res.data);
            resolve(res)
        }).catch(error => {
            reject(error)
        })
    })
}