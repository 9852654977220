import gql from 'graphql-tag'

export const FETCH_COMMENTS = gql`
    query fetchComments ( 
        $page: Int
        $project_id: Int
    ){
        comments( 
            page: $page,
            project_id: $project_id
        ){
            data {
                id
                name
                comment
                created_at
                project {
                    id
                    name
                }
                media {
                    id
                    full_url
                }
            }
            total
            per_page
            current_page
            from
            to
            last_page
            has_more_pages
        }
    }
`

export const CREATE_COMMENTS = gql`
    mutation createComment(
        $name: String, 
        $comment: String,
        $project_id: Int
        $media: Upload
    ) {
        createComment( 
            name: $name,
            comment: $comment,
            project_id: $project_id
            media: $media
        ) {
            id
            name
            comment
            project {
                id
                name
            }
            media {
                id
                full_url
            }
            created_at
        }
    }
`

export const EDIT_COMMENTS = gql`
    mutation editComment(
        $comment_id: String!
        $name: String
        $comment: String
        $project_id: Int
        $media: Upload
    ) {
        editComment(
            comment_id: $comment_id,
            name: $name,
            comment: $comment,
            project_id: $project_id
            media: $media
        ) {
            id
            name
            comment
            project {
                id
                name
            }
            media {
                id
                full_url
            }
            created_at
        }
    }
`