export function SET_PROPERTIES (state, data) {
    state.properties = data
}

export function SET_PROPERTIES_FROM_HOME (state, data) {
    state.properties = data
}
export function UPDATE_PROPERTIES_FROM_EXCEL (state, data) {  
    state.properties.data.forEach(element => {
        data.data.updatePropertiesByExcel.properties.forEach(dataElement => {
            if(dataElement.id == element.id){
                element.price = dataElement.price;
                element.visible = dataElement.visible;
                element.bookeable = dataElement.bookeable;
                element.promisable = dataElement.promisable;
                element.vulnerable = dataElement.vulnerable;
                element.sectores_medios = dataElement.sectores_medios; 
            }
        });
    });
}

export function UPDATE_AUDITS_PROPERTY(state, data) {
    var propiedad = undefined;
    if(state.properties.data){
        propiedad = state.properties.data.find( element => data.data.editProperty.id === element.id );
    }else{
        if(state.properties.properties){
            propiedad = state.properties.properties.data.find( element => data.data.editProperty.id === element.id );
        }
    }
    if(propiedad != undefined){
        if(data.data.editProperty.audits != undefined){
            propiedad.audits = data.data.editProperty.audits;
        }
    }
}  


export function SET_WAREHOUSES (state, data) {
    state.warehouses = data
}

export function SET_PARKINGLOTS (state, data) {
    state.parkinglots = data
}
export function UPDATE_AUDITS_PARKINGLOT(state, data) { 
    var propiedad = undefined;
    if(state.parkinglots.data){
        propiedad = state.parkinglots.data.find( element => data.data.editParkinglot.id === element.id );
    }else{
        if(state.parkinglots.parkinglots){
            propiedad = state.parkinglots.parkinglots.data.find( element => data.data.editParkinglot.id === element.id );
        }
    }
    if(propiedad != undefined){
        if(data.data.editParkinglot.audits != undefined){ 
            propiedad.audits = data.data.editParkinglot.audits;
        }
    }
}

export function UPDATE_AUDITS_WAREHOUSE(state, data) { 
    var propiedad = undefined;
    if(state.warehouses.data){
        propiedad = state.warehouses.data.find( element => data.data.editWarehouse.id === element.id );
    }else{
        if(state.warehouses.warehouses){
            propiedad = state.warehouses.warehouses.data.find( element => data.data.editWarehouse.id === element.id );
        }
    }
    if(propiedad != undefined){
        if(data.data.editWarehouse.audits != undefined){ 
            propiedad.audits = data.data.editWarehouse.audits;
        }
    }
}

export function SET_PROPERTY_PROJECT (state, data) {
    state.filter_project = data
}

export function SET_PROPERTY_SELECT (state, data) {
    state.property_select = data
}

export function SET_PROPERTIES_EXTRA_DATA (state, data) {
    state.properties_extra_data = data
}
