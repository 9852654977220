export function SET_PROJECTS (state, data) {
    state.projects = data.projects
}

export function SET_WEBHOOKS (state, data) {
    state.webhooks = data
}

export function SET_WEBHOOKS_RECORDS (state, data) {
    state.webhooksRecords = data
}

export function UPDATE_ONE_PROJECT (state, data) { 
    state.projects.data.forEach(StateElement => {
        if(StateElement.id == data.data.editProject.id){ 
            StateElement.type = data.data.editProject.type;
            if(data.data.editProject != undefined){
                if(data.data.editProject.projectrestriction != undefined){
                    StateElement.projectrestriction = data.data.editProject.projectrestriction;
                }
                if(data.data.editProject.projectadditional != undefined){
                    StateElement.projectadditional = data.data.editProject.projectadditional;
                }
            }
        }
    }); 
}

export function UPDATE_ONE_PROJECT_CONTACT (state, data) { 
    state.projects.data.forEach(StateElement => {
        if(StateElement.id == data.addProjectImage.id){ 
            StateElement.executiveemail = data.addProjectImage.executiveemail;
            StateElement.executivename = data.addProjectImage.executivename;
            StateElement.executivephone = data.addProjectImage.executivephone;
        }
    }); 
}

export function ADD_ONE_PROJECT (state, data) {  
    data.visible = true
    state.projects.data.unshift(data)
} 

export function UPDATE_PLAN (state, data) {  
    state.projects.data.forEach(StateElement => {
        if(StateElement.id == data.editPlanProject.project.id){
            StateElement.planspayment = data.editPlanProject.project.planspayment; 
        }
    }); 
} 