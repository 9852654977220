import Vue from 'vue'
import VueRouter from 'vue-router'
import store from '@/store'

import auth from './routes/auth'
import home from './routes/home'
import users from './routes/users'
import projects from './routes/projects'
import sales from './routes/sales'
import business from './routes/business'
import agencies from './routes/agencies'
import preapprovals from './routes/preapprovals'
import news from './routes/news'
import contacts from './routes/contacts'
import plans from './routes/plans'
import others from './routes/others'
import comments from './routes/comments'

Vue.use(VueRouter)

const router = new VueRouter({
  mode: 'history',
  scrollBehavior() {
    return { x: 0, y: 0 }
  },
  routes: [
    {   path: '/',
        redirect: { name: 'login' }
    },
    ...auth,
    ...home,
    ...users,
    ...sales,
    ...agencies,
    ...preapprovals,
    ...news,
    ...contacts,
    ...plans,
    ...others,
    ...projects,
    ...business,
    ...comments,
    {
        path: '*',
        redirect: 'error-404',
    },
  ],
})

router.afterEach(() => {
  const appLoading = document.getElementById('loading-bg')
  if (appLoading) {
    appLoading.style.display = "none";
  }
})

router.beforeEach((to, from, next) => {
  const requiresAuth = to.matched.some(record => record.meta.requiresAuth)
  const isLogged = store.state.auth.isLogged
  const role = store.state.profile.role

  if (!requiresAuth && isLogged && to.path === '/') {
    if(role.name === 'PreapprovalUser') {
      return next('/preapprovals')
    }else{
      return next('/home')
    }
  }

  if (requiresAuth && !isLogged && to.path !== '/') {
    next('/')
  } else {
    next()
  }

})

export default router
