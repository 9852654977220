import Vue from 'vue'
import store from '@/store'
import graphqlClient from '@/plugins/apollo'
import {
  CHANGE_PLAN,
  CHANGE_VISIBLE,
  EDIT_PROJECT_IMPORTANT,
  CREATE_PROJECTS,
  EDIT_PROJECTS,
  FETCH_PROJECTS,
  DELETE_PROJECTS,
  ADD_PROJECT_IMAGE,
  REMOVE_PROJECT_IMAGE,
  MODELS_BY_PROJECT,
  EDIT_MEDIA_PROPERTY_BY_MODEL,
  DELETE_MEDIA_PROJECT,
  CHECK_PROPERTIES_EXCEL,
  SAVE_PROPERTIES_EXCEL,
  CREATE_WEBHOOKS,
  FETCH_WEBHOOKS,
  DELETE_WEBHOOKS,
  EDIT_WEBHOOKS,
  FETCH_WEBHOOKS_RECORDS,
  ADD_PROJECT_IMAGE_CONTACT
} from '@/services/graphql/projects'

export async function createProjects(context, project) {
  return new Promise((resolve, reject) => {
    //let projects_related = project.projects_related.map((e) => e = e.toString());

    graphqlClient
      .mutate({
        mutation: CREATE_PROJECTS,
        context: {
          hasUpload: true // Important!
        },
        variables: {
          type: project.type.toString(),
          name: project.project_name,
          description: project.project_description,
          //project_status_id: project.project_status_id,
          //reserve_validity: project.reserve_validity,
          //quotation_validity: project.quotation_validity,
          crm_id: project.crm_id,
          crm_zp: project.crm_zp,
          crm_email: project.crm_email,
          //projects_related: projects_related,
          price_from: project.price_from,
          bedrooms_from: project.bedrooms_from,
          //bathrooms_from: project.bathrooms_from,
          area_from: project.area_from,
          //deliver_date: project.deliver_date,
          address: project.address,
          latitude: project.latitude.toString(),
          longitude: project.longitude.toString(),
          subsidy_type_id: project.subsidy_type,
          plans_additional_id: project.plans_additional,
          commune_id: project.commune,
          type_property: project.property_type,
          brochurepdf: project.brochure,
          nube360: project.nube360,
          tour360: project.tour360,
          executive_name: project.executive_name_list,
          executive_phone: project.executive_phone_list,
          executive_email: project.executive_email_list,
          executive_images: project.executive_images,
          flow_api_key: project.api_key,
          flow_secret_key: project.secret_key,
          //reserve_currency: project.money_type,
          //reserve_amount: parseInt(project.reserve_amount),
          //reserve_type: project.reserve_type,
          //promises_type: project.promises_type,
          email_query_list: project.email_query_list,
          email_quotation_list: project.email_quotation_list,
          //email_promise_list: project.email_promise_list,
          email_booking_list: project.email_booking_list,
          //email_disengagement_list: project.email_disengagement_list,
          logo: project.miniature.file,
          //cover_page: project.portrait.file,
          gallery_photos: project.gallery_saved,
          gallery_contact_photos: project.gallery_contacts_saved,
          realstate_agency_id: parseInt(project.real_state_agency),
          //legal_quote_text: project.legal_quote_text,
          //summary: project.summary,
          orden: project.orden,
          sell_subsidies: project.sell_subsidies.toString(),
          //project_stage: project.project_stage.toString(),
          chatbot_url: project.chatbot_url,
          exercise_area: project.exercise_area,
          green_area: project.green_area,
          bikers: project.bikers,
          games_accessibility: project.games_accessibility,
          calisthenics_area: project.calisthenics_area,
          stores: project.stores,
          events_room: project.events_room,
          clean_points: project.clean_points,
          elevators: project.elevators,
          controlled_access: project.controlled_access,
          quincho: project.quincho,
          swimming_pool: project.swimming_pool,
          hospital: project.hospital,
          subway_station: project.subway_station
        },
        update: (store, { data: { createProject } }) => {
          context.commit('ADD_ONE_PROJECT', createProject)
        }
      })
      .then(res => {
        resolve(res)
      })
      .catch(error => {
        reject(error)
      })
  })
}

export async function editProjects(context, project) {
  return new Promise((resolve, reject) => {
    //let projects_related = project.projects_related.map((e) => e = e.toString());
    graphqlClient
      .mutate({
        mutation: EDIT_PROJECTS,
        variables: {
          project_id: project.id,
          type: project.type,
          name: project.project_name,
          orden: project.orden,
          description: project.project_description,
          //project_status_id: project.project_status_id,
          //reserve_validity: project.reserve_validity,
          //quotation_validity: project.quotation_validity,
          crm_id: project.crm_id,
          crm_zp: project.crm_zp,
          crm_email: project.crm_email,
          //projects_related: projects_related,
          price_from: project.price_from,
          bedrooms_from: project.bedrooms_from,
          //bathrooms_from: project.bathrooms_from,
          area_from: project.area_from,
          //deliver_date: project.deliver_date,
          address: project.address,
          latitude: project.latitude.toString(),
          longitude: project.longitude.toString(),
          subsidy_type_id: project.subsidy_type,
          plans_additional_id: project.plans_additional,
          commune_id: project.commune,
          type_property: project.property_type,
          brochurepdf: project.brochure,
          nube360: project.nube360,
          tour360: project.tour360,
          executive_name: project.executive_name_list,
          executive_phone: project.executive_phone_list,
          executive_email: project.executive_email_list,
          email_quotation_list: project.email_quotation_list,
          //email_promise_list: project.email_promise_list,
          email_booking_list: project.email_booking_list,
          //email_disengagement_list: project.email_disengagement_list,
          realstate_agency_id: project.real_state_agency.toString(),
          flow_api_key: project.api_key,
          flow_secret_key: project.secret_key,
          //reserve_currency: project.money_type,
          //reserve_amount: project.reserve_amount,
          //reserve_type: project.reserve_type,
          //promises_type: project.promises_type,
          //legal_quote_text: project.legal_quote_text,
          //summary: project.summary,
          sell_subsidies: project.sell_subsidies.toString(),
          //project_stage: project.project_stage.toString(),
          chatbot_url: project.chatbot_url,
          exercise_area: project.exercise_area,
          green_area: project.green_area,
          bikers: project.bikers,
          games_accessibility: project.games_accessibility,
          calisthenics_area: project.calisthenics_area,
          stores: project.stores,
          events_room: project.events_room,
          clean_points: project.clean_points,
          elevators: project.elevators,
          controlled_access: project.controlled_access,
          quincho: project.quincho,
          swimming_pool: project.swimming_pool,
          reserve_crm: project.reserve_crm,
          reserve_crm_zp: project.reserve_crm_zp,
          show_logo_realstate: project.show_logo_realstate,
          hospital: project.hospital,
          subway_station: project.subway_station,
          show_section_news: project.show_section_news,
          integration_crm: project.integration_crm,
          promotions: project.promotions === '' ? null : project.promotions,
          renta_minima: project.minimumSalary === '' ? null : Number(project.minimumSalary),
          video_youtube_id: project.video_youtube_id,
        },
        update: (store, { data: { EDIT_PROJECTS } }) => { }
      })
      .then(res => {
        context.commit('UPDATE_ONE_PROJECT', res)
        resolve(res)
      })
      .catch(error => {
        reject(error)
      })
  })
}

export async function fetchProjects(context, data) {
  return new Promise((resolve, reject) => {
    let _page = data ? data.page : 1
    var project_id = []
    if (data.project_id && data.project_id.length > 0) {
      project_id = data.project_id.map(project => project.toString())
    }
    var real_state_agency = ''
    if (data.real_state_agency) {
      real_state_agency = data.real_state_agency
    }

    graphqlClient
      .query({
        query: FETCH_PROJECTS,
        variables: {
          page: _page,
          project_id: project_id,
          real_state_agency: real_state_agency.toString()
        },
        fetchPolicy: 'network-only'
      })
      .then(res => {
        res.data.projects.data.map(
          element =>
          (element.plansbutton =
            element.planspayment == null
              ? false
              : element.planspayment.id == 2
                ? true
                : false)
        )
        context.commit('SET_PROJECTS', res.data)
        resolve(res)
      })
      .catch(error => {
        reject(error)
      })
  })
}

export async function deleteProjects(context, project) {
  return new Promise((resolve, reject) => {
    graphqlClient
      .mutate({
        mutation: DELETE_PROJECTS,
        variables: {
          id: project.id
        },
        update: (store, { data: { DELETE_PROJECT } }) => {
          const data = store.readQuery({
            query: FETCH_PROJECTS,
            variables: { page: project.page }
          })
          data.projects.data.forEach(function (value, index, object) {
            if (value.id === project.id) {
              object.splice(index, 1)
            }
          })
          context.commit('SET_PROJECTS', data)
        }
      })
      .then(res => {
        resolve(res)
      })
      .catch(error => {
        reject(error)
      })
  })
}

export async function checkPropertiesExcel(context, excel) {
  return new Promise((resolve, reject) => {
    graphqlClient
      .mutate({
        mutation: CHECK_PROPERTIES_EXCEL,
        context: {
          hasUpload: true // Important!
        },
        variables: {
          excel: excel
        }
      })
      .then(res => {
        resolve(res)
      })
      .catch(error => {
        reject(error)
      })
  })
}

export async function savePropertiesExcel(context, data) {
  return new Promise((resolve, reject) => {
    graphqlClient
      .mutate({
        mutation: SAVE_PROPERTIES_EXCEL,
        context: {
          hasUpload: true // Important!
        },
        variables: {
          project_id: data.project_id,
          jsonExcel: data.jsonExcel
        }
      })
      .then(res => {
        resolve(res)
      })
      .catch(error => {
        reject(error)
      })
  })
}

export async function exportDemoProperties(context) {
  return new Promise((resolve, reject) => {
    Vue.axios({
      method: 'GET',
      responseType: 'blob',
      url: 'api/excelDemoProperties',
      headers: { Authorization: `Bearer ${store.state.auth.accessToken}` }
    })
      .then(res => {
        let blob = new Blob([res.data], { type: 'application/vnd.ms-excel' })
        let url = window.URL.createObjectURL(blob)
        let a = document.createElement('a')
        a.href = url
        a.download = 'Unidades.xlsx'
        a.click()
        window.URL.revokeObjectURL(url)
      })
      .catch(error => {
        reject(error)
      })
  })
}

export async function exportProjects(context, data) {
  return new Promise((resolve, reject) => {
    Vue.axios({
      method: 'GET',
      params: data,
      responseType: 'blob',
      url: 'api/excelProjects',
      headers: { Authorization: `Bearer ${store.state.auth.accessToken}` }
    })
      .then(res => {
        let blob = new Blob([res.data], { type: 'application/vnd.ms-excel' })
        let url = window.URL.createObjectURL(blob)
        let a = document.createElement('a')
        a.href = url
        a.download = 'Proyectos.xlsx'
        a.click()
        window.URL.revokeObjectURL(url)
        resolve(res)
      })
      .catch(error => {
        reject(error)
      })
  })
}

export async function changeVisible(context, project) {
  return new Promise((resolve, reject) => {
    graphqlClient
      .mutate({
        mutation: CHANGE_VISIBLE,
        variables: {
          visible: project.visible,
          project_id: project.project_id
        },
        update: (store, { data: { CHANGE_VISIBLE } }) => {
          // const data = store.readQuery({query: FETCH_PROJECTS,
          // variables: { page: 1 }})
          // context.commit('SET_PROJECTS',data)
        }
      })
      .then(res => {
        resolve(res)
      })
      .catch(error => {
        reject(error)
      })
  })
}

export async function changePlan(context, project) {
  return new Promise((resolve, reject) => {
    graphqlClient
      .mutate({
        mutation: CHANGE_PLAN,
        variables: {
          plan: project.plan.toString(),
          project_id: project.project_id
        },
        update: (store, { data: { CHANGE_PLAN } }) => { }
      })
      .then(res => {
        context.commit('UPDATE_PLAN', res.data)
        resolve(res)
      })
      .catch(error => {
        reject(error)
      })
  })
}

export async function editProjectImportant(context, project) {
  return new Promise((resolve, reject) => {
    graphqlClient
      .mutate({
        mutation: EDIT_PROJECT_IMPORTANT,
        variables: {
          project_id: project.project_id,
          important: project.important
        },
        update: (store, { data: { EDIT_PROJECT_IMPORTANT } }) => {
          // const data = store.readQuery({query: FETCH_PROJECTS,
          // variables: { page: 1 }})
          // context.commit('SET_PROJECTS',data)
        }
      })
      .then(res => {
        resolve(res)
      })
      .catch(error => {
        reject(error)
      })
  })
}

export async function addProjectImage(context, data) {
  return new Promise((resolve, reject) => {
    graphqlClient
      .mutate({
        mutation: ADD_PROJECT_IMAGE,
        context: {
          hasUpload: true // Important!
        },
        variables: {
          project_id: data.project_id,
          type: data.type,
          files: data.file
        }
      })
      .then(res => {
        resolve(res)
      })
      .catch(error => {
        reject(error)
      })
  })
}

export async function addProjectImageContact(context, data) {
  return new Promise((resolve, reject) => {
    graphqlClient
      .mutate({
        mutation: ADD_PROJECT_IMAGE_CONTACT,
        context: {
          hasUpload: true // Important!
        },
        variables: {
          project_id: data.project_id,
          type: data.type,
          files: data.file,
          indexs: data.indexs
        }
      })
      .then(res => {
        //context.commit('UPDATE_ONE_PROJECT_CONTACT',res.data)
        resolve(res)
      })
      .catch(error => {
        reject(error)
      })
  })
}

export async function removeProjectImage(context, data) {
  return new Promise((resolve, reject) => {
    graphqlClient
      .mutate({
        mutation: REMOVE_PROJECT_IMAGE,
        variables: {
          project_id: data.project_id,
          media_id: data.media_id
        }
      })
      .then(res => {
        resolve(res)
      })
      .catch(error => {
        reject(error)
      })
  })
}

export async function modelsByProject(context, data) {
  return new Promise((resolve, reject) => {
    graphqlClient
      .query({
        query: MODELS_BY_PROJECT,
        variables: {
          project_id: data.project_id.toString(),
          cache: data.cache.toString()
        }
      })
      .then(res => {
        resolve(res)
      })
      .catch(error => {
        reject(error)
      })
  })
}

export async function editMediaPropertyByModel(context, data) {
  return new Promise((resolve, reject) => {
    graphqlClient
      .mutate({
        mutation: EDIT_MEDIA_PROPERTY_BY_MODEL,
        context: {
          hasUpload: true // Important!
        },
        variables: {
          project_id: data.project_id.toString(),
          models: data.models,
          file: data.file
        }
      })
      .then(res => {
        resolve(res)
      })
      .catch(error => {
        reject(error)
      })
  })
}

export async function deleteMediaProject(context, data) {
  return new Promise((resolve, reject) => {
    graphqlClient
      .mutate({
        mutation: DELETE_MEDIA_PROJECT,
        variables: {
          project_id: data.project_id,
          media_id: data.media_id
        }
      })
      .then(res => {
        resolve(res)
      })
      .catch(error => {
        reject(error)
      })
  })
}

export async function createWebhooks(context, webhook) {
  return new Promise((resolve, reject) => {
    graphqlClient
      .mutate({
        mutation: CREATE_WEBHOOKS,
        variables: {
          project_id: webhook.project_id,
          name: webhook.name,
          event_type: webhook.event,
          url: webhook.url,
          description: webhook.description
        },
        update: (store, { data: { CREATE_WEBHOOKS } }) => {
          const data = store.readQuery({ query: FETCH_WEBHOOKS })
          data.webhooks.push(CREATE_WEBHOOKS)
          store.writeQuery({ query: FETCH_WEBHOOKS, data })
        }
      })
      .then(res => {
        resolve(res)
      })
      .catch(error => {
        reject(error)
      })
  })
}

export async function fetchWebhooks(context, project_id) {
  return new Promise((resolve, reject) => {
    graphqlClient
      .query({
        query: FETCH_WEBHOOKS,
        variables: {
          project_id: project_id
        }
      })
      .then(res => {
        context.commit('SET_WEBHOOKS', res.data.webhooks)
        resolve(res)
      })
      .catch(error => {
        reject(error)
      })
  })
}

export async function fetchWebhooksRecords(context, project_id) {
  return new Promise((resolve, reject) => {
    graphqlClient
      .query({
        query: FETCH_WEBHOOKS_RECORDS,
        variables: {
          project_id: project_id
        }
      })
      .then(res => {
        context.commit('SET_WEBHOOKS_RECORDS', res.data.webhooksrecords)
        resolve(res)
      })
      .catch(error => {
        reject(error)
      })
  })
}

export async function deleteWebhooks(context, webhook) {
  return new Promise((resolve, reject) => {
    graphqlClient
      .mutate({
        mutation: DELETE_WEBHOOKS,
        variables: {
          id: webhook.id
        },
        update: (store, { data: { DELETE_WEBHOOKS } }) => {
          const data = store.readQuery({ query: FETCH_WEBHOOKS })
          data.webhooks.forEach(function (value, index, object) {
            if (value.id == webhook.id) {
              object.splice(index, 1)
            }
          })
          store.writeQuery({ query: FETCH_WEBHOOKS, data })
        }
      })
      .then(res => {
        resolve(res)
      })
      .catch(error => {
        reject(error)
      })
  })
}

export async function editWebhooks(context, webhook) {
  return new Promise((resolve, reject) => {
    graphqlClient
      .mutate({
        mutation: EDIT_WEBHOOKS,
        variables: {
          webhook_id: webhook.id,
          name: webhook.name,
          event_type: webhook.event,
          url: webhook.url,
          description: webhook.description
        },
        update: (store, { data: { EDIT_WEBHOOKS } }) => {
          const data = store.readQuery({ query: FETCH_WEBHOOKS })
          context.commit('SET_WEBHOOKS', data)
          store.writeQuery({ query: FETCH_WEBHOOKS, data })
        }
      })
      .then(res => {
        resolve(res)
      })
      .catch(error => {
        reject(error)
      })
  })
}
