import gql from 'graphql-tag'

export const FETCH_LOCATIONS = gql`
    query fetchLocations{
        regions{
            id
            name
            cities{
                id
                name
                communes{
                    id
                    name
                }
            }
        }
    }
`