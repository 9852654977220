import graphqlClient from '@/plugins/apollo'
import {
  FETCH_AGENCIES,
  CREATE_AGENCIES,
  EDIT_AGENCIES,
  CHANGE_AGENCIES,
  EDIT_REALSTATEAGENCY_IMPORTANT
} from '@/services/graphql/agencies'

export async function fetchAgencies (context, data) {
  return new Promise((resolve, reject) => {
    graphqlClient
      .query({
        query: FETCH_AGENCIES,
        variables: data
      })
      .then(res => {
        context.commit('SET_AGENCIES', res.data)
        resolve(res)
      })
      .catch(error => {
        reject(error)
      })
  })
}

export async function createAgencies (context, agency) {
  return new Promise((resolve, reject) => {
    graphqlClient
      .mutate({
        mutation: CREATE_AGENCIES,
        context: {
          hasUpload: true // Important!
        },
        variables: {
          name: agency.name,
          official_name: agency.official,
          representative: agency.contact,
          address: agency.address,
          phone: agency.phone,
          email: agency.email,
          description: agency.description,
          logo: agency.logo,
          cover_page: agency.cover_page,
          orden: agency.orden,
          url_site: agency.url_site,
          restriction_quantity: agency.restriction_quantity
        },
        update: (store, { data: { createRealStateAgency } }) => {
          // const data = store.readQuery({query: FETCH_AGENCIES, variables: {page: 1}})
          // data.realstateagencies.data.unshift(createRealStateAgency)
          // store.writeQuery({query: FETCH_AGENCIES, data})
        }
      })
      .then(res => {
        context.commit('ADD_ONE_AGENCIES', res.data)
        resolve(res)
      })
      .catch(error => {
        console.log(error)
        reject(error)
      })
  })
}

export async function updateAgencies (context, agency) {
  return new Promise((resolve, reject) => {
    graphqlClient
      .mutate({
        mutation: EDIT_AGENCIES,
        variables: {
          agency_id: agency.id,
          name: agency.name,
          official_name: agency.official,
          representative: agency.contact,
          address: agency.address,
          phone: agency.phone,
          description: agency.description,
          email: agency.email,
          url_site: agency.url_site,
          logo: agency.logo,
          cover_page: agency.cover_page,
          orden: agency.orden,
          restriction_quantity: agency.restriction_quantity
        },
        update: (store, { data: { EDIT_AGENCIES } }) => {
          // const data = store.readQuery({query: FETCH_AGENCIES})
          // context.commit('SET_AGENCIES',data)
          // store.writeQuery({query: FETCH_AGENCIES, data})
          // const data = store.readQuery({query: FETCH_AGENCIES,
          // variables: { page: 1 }})
          // context.commit('SET_AGENCIES',data)
        }
      })
      .then(res => {
        context.commit('UPDATE_ONE_AGENCIES', res.data)
        resolve(res)
      })
      .catch(error => {
        reject(error)
      })
  })
}

export async function changeStatus (context, agency) {
  return new Promise((resolve, reject) => {
    graphqlClient
      .mutate({
        mutation: CHANGE_AGENCIES,
        variables: {
          id: agency.id,
          visibility: agency.visible
        },
        update: (store, { data: { CHANGE_AGENCIES } }) => {
          // const data = store.readQuery({query: FETCH_AGENCIES,
          // variables: { page: 1 }})
          // context.commit('SET_AGENCIES',data)
        }
      })
      .then(res => {
        resolve(res)
      })
      .catch(error => {
        reject(error)
      })
  })
}

export async function editRealstateAgencyImportant (context, project) {
  return new Promise((resolve, reject) => {
    graphqlClient
      .mutate({
        mutation: EDIT_REALSTATEAGENCY_IMPORTANT,
        variables: {
          agency_id: project.agency_id,
          important: project.important
        },
        update: (store, { data: { CHANGE_VISIBLE } }) => {
          // const data = store.readQuery({query: FETCH_AGENCIES,
          // variables: { page: 1 }})
          // context.commit('SET_AGENCIES',data)
        }
      })
      .then(res => {
        resolve(res)
      })
      .catch(error => {
        reject(error)
      })
  })
}
