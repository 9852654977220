import graphqlClient from '@/plugins/apollo'
import { FETCH_BUSINESS, FETCH_PIPELINES, EXPORT_BUSINESS, CHANGE_STATE, FETCH_BUSINESS_PER_TYPE, SET_FILTERS_BUSINESS_VIEW, SET_FILTERS_BUSINESS} from '@/services/graphql/business'

export function fetchBusinessPerTypeNoAsync (context, filter) {
    if(filter.general != "" && filter.general != null){
        var general = filter.general
    }
    if(filter.project != "" && filter.project != null){
        var project = filter.project
    }
    if(filter.start != "" && filter.start != null){
        var date_ini = filter.start
    }
    if(filter.end != "" && filter.end != null){
        var date_end = filter.end
    }
    if(filter.rukan != "" && filter.rukan != null){
        var rukan = filter.rukan
    }
    if(filter.pre_approval != "" && filter.pre_approval != null){
        var pre_approval = filter.pre_approval
    }
    var subquery = filter.subquery
    var subpage = filter.subpage
    
    if(filter.page != ""){
        var page = filter.page
    }
    return new Promise((resolve, reject) => {
        graphqlClient.query({
            query: FETCH_BUSINESS_PER_TYPE,
            variables: {
                customer_name_rut_email_phone: general,
                project_id: project,
                date_ini: date_ini,
                date_end: date_end,
                page: page,
                rukans_vig: rukan,
                pre_approvals_status: pre_approval,
                subpage: subpage.toString(),
                subquery: subquery.toString(),
            }
        }).then(res => {
            context.commit('SET_BUSINESS_PER_TYPE',res.data)
            resolve(res)
        }).catch(error => {
            reject(error)
        })
    })
}

export function fetchBusinessPerTypeNoAsyncClean (context, filter) {
    if(filter.general != "" && filter.general != null){
        var general = filter.general
    }
    if(filter.project != "" && filter.project != null){
        var project = filter.project
    }
    if(filter.start != "" && filter.start != null){
        var date_ini = filter.start
    }
    if(filter.end != "" && filter.end != null){
        var date_end = filter.end
    }
    if(filter.rukan != "" && filter.rukan != null){
        var rukan = filter.rukan
    }
    if(filter.pre_approval != "" && filter.pre_approval != null){
        var pre_approval = filter.pre_approval
    }
    var subquery = filter.subquery
    var subpage = filter.subpage
    
    if(filter.page != ""){
        var page = filter.page
    }
    var incremental = new Date().getTime();

    return new Promise((resolve, reject) => {
        graphqlClient.query({
            query: FETCH_BUSINESS_PER_TYPE,
            variables: {
                customer_name_rut_email_phone: general,
                project_id: project,
                date_ini: date_ini,
                date_end: date_end,
                page: page,
                rukans_vig: rukan,
                pre_approvals_status: pre_approval,
                subpage: subpage,
                subquery: subquery,
                incremental:incremental.toString()
            }
        }).then(res => {
            context.commit('SET_BUSINESS_PER_TYPE_CLEAN',res.data)
            resolve(res)
        }).catch(error => {
            reject(error)
        })
    })
}

export async function fetchBusinessPerType (context, filter) {
    if(filter.general != "" && filter.general != null){
        var general = filter.general
    }
    if(filter.project != "" && filter.project != null){
        var project = filter.project
    }
    if(filter.start != "" && filter.start != null){
        var date_ini = filter.start
    }
    if(filter.end != "" && filter.end != null){
        var date_end = filter.end
    }
    if(filter.rukan != "" && filter.rukan != null){
        var rukan = filter.rukan
    }
    if(filter.pre_approval != "" && filter.pre_approval != null){
        var pre_approval = filter.pre_approval
    }
    
    var incremental = new Date().getTime();
    
    if(filter.page != ""){
        var page = filter.page
    }
    return new Promise((resolve, reject) => {
        graphqlClient.query({
            query: FETCH_BUSINESS_PER_TYPE,
            variables: {
                customer_name_rut_email_phone: general,
                project_id: project,
                date_ini: date_ini,
                date_end: date_end,
                page: page,
                rukans_vig: rukan,
                pre_approvals_status: pre_approval,
                incremental: incremental.toString(),
            }
        }).then(res => {
            context.commit('SET_BUSINESS_PER_TYPE_CLEAN',res.data)
            resolve(res)
        }).catch(error => {
            reject(error)
        })
    })
}

export async function fetchBusiness (context, filter) {
    if(filter.general != "" && filter.general != null){
        var general = filter.general
    }
    if(filter.project != "" && filter.project != null){
        var project = filter.project
    }
    if(filter.start != "" && filter.start != null){
        var date_ini = filter.start
    }
    if(filter.end != "" && filter.end != null){
        var date_end = filter.end
    }
    if(filter.rukan != "" && filter.rukan != null){
        var rukan = filter.rukan
    }
    if(filter.pre_approval != "" && filter.pre_approval != null){
        var pre_approval = filter.pre_approval
    }
    if(filter.page != ""){
        var page = filter.page
    }
    return new Promise((resolve, reject) => {
        graphqlClient.query({
            query: FETCH_BUSINESS,
            variables: {
                customer_name_rut_email_phone: general,
                project_id: project,
                date_ini: date_ini,
                date_end: date_end,
                page: page,
                rukans_vig: rukan,
                pre_approvals_status: pre_approval
            }
        }).then(res => {
            context.commit('SET_BUSINESS',res.data)
            resolve(res)
        }).catch(error => {
            reject(error)
        })
    })
}

export async function fetchPipelines (context) {
    return new Promise((resolve, reject) => {
        graphqlClient.query({
            query: FETCH_PIPELINES
        }).then(res => {
            context.commit('SET_PIPELINES',res.data)
            resolve(res)
        }).catch(error => {
            reject(error)
        })
    })
}

export async function exportBusiness (context, filters) {
    return new Promise((resolve, reject) => {
        graphqlClient.mutate({
            mutation: EXPORT_BUSINESS,
            variables: {
                date_ini: filters.start,
                date_end: filters.end,
                project_id: filters.project,
                pre_approvals_status: filters.pre_approval,
                rukan: filters.rukan,
            },
        }).then(res => {
            resolve(res)
        }).catch(error => {
            reject(error)
        })
    })
}

export async function changeState (context, state) {
    return new Promise((resolve, reject) => {
        graphqlClient.mutate({
            mutation: CHANGE_STATE,
            variables: {
                prospect_id: state.id, 
                pipeline_stage_id: state.pipeline_stage_id,
                description: state.description
            },
            update: (store,{data: {CHANGE_STATE}}) => { 
                const data = store.readQuery({query: FETCH_BUSINESS, variables: {page: 1}})
                context.commit('SET_BUSINESS',data);
                // store.writeQuery({query: FETCH_BUSINESS, data})
            },
        }).then(res => {
            context.commit('UPDATE_ONE_BUSINESS',res)
            resolve(res)
        }).catch(error => {
            console.log(error);
            reject(error)
        })
    })
}

export async function set_filters_business(context, state) {
    context.commit('SET_FILTERS_BUSINESS', state)
}

export async function set_filters_business_view(context, state) {
    context.commit('SET_FILTERS_BUSINESS_VIEW', state)
}

export async function set_filters_business_reload(context, state) {
    context.commit('SET_FILTERS_RELOAD_VIEW', state)
}