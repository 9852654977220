import gql from 'graphql-tag'

export const FETCH_BOOKINGS_ORDERS = gql`
  query fetchBookingsOrders($project_id: String, $status: String, $page: Int) {
    bookingorders(status: $status, project_id: $project_id, page: $page) {
      data {
        id
        amount_paid
        uf_value
        status
        order_number
        datetime_transaction
        gateway_reference
        signature
        created_at
        bookingrequest {
          id
          status
          payments {
            id
            transaction_amount
            token
            email
            status
            promise_request_id
            booking_request_id
            flow_transfer_date
            flow_balance
            flow_taxes
            flow_fee
            flow_currency
            flow_amount
            flow_conversion_rate
            flow_conversion_date
            flow_media
            flow_date
            flow_payer
            flow_commerce_order
            flow_order
            created_at
          }
          contactBooking {
            id
            name
            rut
            email
            phone
          }
          property {
            id
            identifier
            model
            price
            floor
            bedrooms
            bathrooms
            tower
            total_area
            municipal_area
            built_area
            terrace_area
            project {
              id
              name
            }
          }
        }
      }
      total
      per_page
      current_page
      from
      to
      last_page
      has_more_pages
    }
  }
`

export const EDIT_BOOKINGS_ORDERS = gql`
  mutation EditBookingOrders($bookingorder_id: Int!, $status: String!) {
    editBookingOrder(bookingorder_id: $bookingorder_id, status: $status) {
      id
      status
    }
  }
`

export const FETCH_BOOKINGS_REQUESTS = gql`
  query fetchBookingsRequests(
    $project_id: String
    $status: String
    $page: Int
  ) {
    bookingrequests(status: $status, project_id: $project_id, page: $page) {
      data {
        id
        status
        created_at
        payments {
          id
          transaction_amount
          token
          email
          status
          promise_request_id
          booking_request_id
          flow_transfer_date
          flow_balance
          flow_taxes
          flow_fee
          flow_currency
          flow_amount
          flow_conversion_rate
          flow_conversion_date
          flow_media
          flow_date
          flow_payer
          flow_commerce_order
          flow_order
          created_at
        }
        property {
          id
          identifier
          model
          price
          floor
          bedrooms
          bathrooms
          tower
          total_area
          municipal_area
          built_area
          terrace_area
          project {
            id
            name
          }
        }
        contactBooking {
          id
          name
          rut
          email
          phone
        }
      }
      total
      per_page
      current_page
      from
      to
      last_page
      has_more_pages
    }
  }
`

export const EDIT_BOOKINGS_REQUESTS = gql`
  mutation EditBookingRequests($bookingrequest_id: Int!, $status: String!) {
    editBookingRequest(bookingrequest_id: $bookingrequest_id, status: $status) {
      id
      status
    }
  }
`

export const EXPORT_BOOKING_REQUEST_FILE = gql`
  mutation exportBookingRequest($project_id: Int, $status: String) {
    requestBookingRequestFile(project_id: $project_id, status: $status) {
      id
    }
  }
`

export const EXPORT_BOOKING_ORDER_FILE = gql`
  mutation exportBookingOrder($project_id: Int, $status: String) {
    requestBookingOrderFile(project_id: $project_id, status: $status) {
      id
    }
  }
`
