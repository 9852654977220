import graphqlClient from '@/plugins/apollo'
import { FETCH_QUOTATIONS, EXPORT_QUOTATIONS, FETCH_CONSOLIDITY } from '@/services/graphql/quotations'

export async function fetchQuotations(context, filter) {
    if (filter.real_state_agency != "" && filter.real_state_agency != null) { var real_state_agency = filter.real_state_agency }
    if (filter.project != "" && filter.project != null) { var project = filter.project }
    if (filter.start != "" && filter.start != null) { var date_ini = filter.start }
    if (filter.end != "" && filter.end != null) { var date_end = filter.end }
    if (filter.page != "") { var page = filter.page }
    return new Promise((resolve, reject) => {
        graphqlClient.query({
            query: FETCH_QUOTATIONS,
            variables: {
                real_state_agency: real_state_agency ? real_state_agency.toString() : '',
                project_id: project ? project.toString() : '',
                date_ini: date_ini,
                date_end: date_end,
                page: page
            }
        }).then(res => {
            context.commit('SET_QUOTATIONS', res.data)
            resolve(res)
        }).catch(error => {
            reject(error)
        })
    })
}

export async function exportQuotations(context, filters) {
    return new Promise((resolve, reject) => {
        graphqlClient.mutate({
            mutation: EXPORT_QUOTATIONS,
            variables: {
                real_state_agency: filters.real_state_agency.toString(),
                customer_name: "",
                customer_email: "",
                city_id: "",
                date_ini: filters.start,
                date_end: filters.end,
                project_id: filters.project ? filters.project : null,
                unit_id: ""
            },
        }).then(res => {
            resolve(res)
        }).catch(error => {
            reject(error)
        })
    })
}

export async function fetchConsolidityQuotes(context, filter) {
    const date_ini = filter.start != "" && filter.start != null ? filter.start : ''
    const date_end = filter.end != "" && filter.end != null ? filter.end : ''
    return new Promise((resolve, reject) => {
        graphqlClient.query({
            query: FETCH_CONSOLIDITY,
            variables: {
                date_ini: date_ini,
                date_end: date_end,
            }
        }).then(res => {
            context.commit('SET_CONSOLIDITY', res.data)
            resolve(res)
        }).catch(error => {
            reject(error)
        })
    })
}
