import graphqlClient from '@/plugins/apollo'
import {EXPORT_PREAPPROVALS,FETCH_PREAPPROVALS,EDIT_PREAPPROVALS} from '@/services/graphql/preapprovals'

export async function fetchPreapprovals (context, filter) {
    if(filter != undefined){
        if(filter.project != "" && filter.project != null){
            var project = filter.project
        }
        if(filter.status != "" && filter.status != null){
            var status = filter.status
        }
        if(filter.page != ""){
            var page = filter.page
        }
    }
    return new Promise((resolve, reject) => {
        graphqlClient.query({
            query: FETCH_PREAPPROVALS,
            variables: {
                project_id: project,
                status: status,
                page: page
            }
        }).then(res => {
            context.commit('SET_PREAPPROVALS',res.data)
            resolve(res)
        }).catch(error => {
            reject(error)
        })
    })
}

export async function editPreapprovals (context, preapproval) {
    return new Promise((resolve, reject) => {
        graphqlClient.mutate({
            mutation: EDIT_PREAPPROVALS,
            context: {
                hasUpload: true // Important!
            },
            variables: {
                preapproval_id: preapproval.id,
                status: preapproval.status,
                description: preapproval.description,
                file: preapproval.file,
            },
            update: (store,{data: {EDIT_PREAPPROVALS}}) => {
                const data = store.readQuery({query: FETCH_PREAPPROVALS, variables: {page: 1}})
                context.commit('SET_PREAPPROVALS',res.data)
                store.writeQuery({query: FETCH_PREAPPROVALS, data})
            },
        }).then(res => {
            resolve(res)
        }).catch(error => {
            reject(error)
        })
    })
}

export async function exportPreapprovals (context, filters) {
    return new Promise((resolve, reject) => {
        graphqlClient.mutate({
            mutation: EXPORT_PREAPPROVALS,
            variables: {
                project_id: filters.project,
                status: filters.status,
            },
        }).then(res => {
            resolve(res)
        }).catch(error => {
            reject(error)
        })
    })
}