import graphqlClient from '@/plugins/apollo'
import {FETCH_PROJECT_STAGE_TYPES,FETCH_PROJECTS,FETCH_PROFILE,FETCH_USERSREALSTATEAGENCIES,FETCH_REALSTATEAGENCIES,FETCH_LOCATIONS,EDIT_USER_PROFILE,FETCH_PROJECT_STATUS,FETCH_PLAN_ADITTIONAL_TYPES,FETCH_SUBSIDY_TYPES,FETCH_REALSTATEAGENCIES_NO_PAGINATION} from '@/services/graphql/profile'

export async function fetchProfile (context) {
    return new Promise((resolve, reject) => {
        graphqlClient.query({
            query: FETCH_PROFILE
        }).then(res => {
            context.commit('SET_PROFILE',res.data)
            resolve(res)
        }).catch(error => {
            reject(error)
        })
    })
}

export async function fetchUsersRealStateAgencies (context, data) {
    return new Promise((resolve, reject) => {
        graphqlClient.query({
            query: FETCH_USERSREALSTATEAGENCIES,
            variables: {
                id: data
            }
        }).then(res => {
            context.commit('SET_USERSREALSTATEAGENCIES',res.data)
            resolve(res)
        }).catch(error => {
            reject(error)
        })
    })
}

export async function fetchRealStateAgencies (context) {
    return new Promise((resolve, reject) => {
        graphqlClient.query({
            query: FETCH_REALSTATEAGENCIES
        }).then(res => {
            context.commit('SET_REALSTATEAGENCIES',res.data)
            resolve(res)
        }).catch(error => {
            reject(error)
        })
    })
}

export async function fetchRealStateAgenciesNoPagination (context) {
    return new Promise((resolve, reject) => {
        graphqlClient.query({
            query: FETCH_REALSTATEAGENCIES_NO_PAGINATION
        }).then(res => {
            context.commit('SET_REALSTATEAGENCIES_NO_PAGINATION',res.data)
            resolve(res)
        }).catch(error => {
            reject(error)
        })
    })
}
export async function fetchProjects(context) {
    return new Promise((resolve, reject) => {
        graphqlClient.query({
            query: FETCH_PROJECTS
        }).then(res => {
            context.commit('SET_PROJECTS', res.data)
            resolve(res)
        }).catch(error => {
            reject(error)
        })
    })
}

export async function fetchProjectsRelatedToMe (context) {
    return new Promise((resolve, reject) => {
        graphqlClient.query({
            query: FETCH_PROJECTS
        }).then(res => {
            context.commit('SET_PROJECTS',res.data)
            resolve(res)
        }).catch(error => {
            reject(error)
        })
    })
}

export async function fetchLocations (context) {
    return new Promise((resolve, reject) => {
        graphqlClient.query({
            query: FETCH_LOCATIONS
        }).then(res => {
            context.commit('SET_LOCATIONS',res.data)
            resolve(res)
        }).catch(error => {
            reject(error)
        })
    })
}

export async function editUserProfile (context,user) { 
    return new Promise((resolve, reject) => {
        var variables = {
            name: user.name,
            last_name: user.lastName,
            password: user.password,
        };
        graphqlClient.mutate({
            mutation: EDIT_USER_PROFILE,
            context: {},
            variables: variables, 
        }).then(res => {
            resolve(res)
        }).catch(error => {
            reject(error)
        })
    })
}

export async function fetchProjectStatus (context) {
    return new Promise((resolve, reject) => {
        graphqlClient.query({
            query: FETCH_PROJECT_STATUS
        }).then(res => {
            context.commit('SET_PROJECT_STATUS',res.data)
            resolve(res)
        }).catch(error => {
            reject(error)
        })
    })
}

export async function fetchSubsidyTypes (context) {
    return new Promise((resolve, reject) => {
        graphqlClient.query({
            query: FETCH_SUBSIDY_TYPES
        }).then(res => {
            context.commit('SET_SUBSIDY_TYPES',res.data)
            resolve(res)
        }).catch(error => {
            reject(error)
        })
    })
}

export async function fetchPlanAdittionalTypes (context) {
    return new Promise((resolve, reject) => {
        graphqlClient.query({
            query: FETCH_PLAN_ADITTIONAL_TYPES
        }).then(res => { 
            context.commit('SET_PLAN_ADITTIONAL_TYPES',res.data)
            resolve(res)
        }).catch(error => {
            console.log(error);
            reject(error)
        })
    })
}

export async function fetchProjectStageTypes (context) {
    return new Promise((resolve, reject) => {
        graphqlClient.query({
            query: FETCH_PROJECT_STAGE_TYPES
        }).then(res => {
            context.commit('SET_PROJECT_STAGE_TYPES',res.data)
            resolve(res)
        }).catch(error => {
            console.log(error);
            reject(error)
        })
    })
}