import graphqlClient from '@/plugins/apollo'
import {FETCH_LOCATIONS} from '@/services/graphql/locations'

export async function fetchLocations (context) {
    return new Promise((resolve, reject) => {
        graphqlClient.query({
            query: FETCH_LOCATIONS
        }).then(res => {
            context.commit('SET_LOCATIONS',res.data)
            resolve(res)
        }).catch(error => {
            reject(error)
        })
    })
}