import store from "@/store";

export default [
    {
        path: '/plans/create',
        name: 'plans-create',
        component: () => import('@/views/plans/PlansCreate'),
        meta: {
            requiresAuth: true,
            navActiveLink: 'plans-create',
            breadcrumb: [
                {
                    text: 'Planes',
                    to: '/plans/create'
                },
                {
                    text: 'Contrata tu plan',
                    active: true,
                },
            ],
        },
        beforeEnter(to, from, next) {
            const role = store.state.profile.role
            if (role.name === 'Admin' || role.name === 'RealstateAdmin' || role.name === 'RealstateVendor') next()
            else next({ name: 'error-403' })
        },
    }, 
    {
        path: '/plans/contract',
        name: 'plans-contract',
        component: () => import('@/views/plans/PlansContract'),
        meta: {
            requiresAuth: true,
            navActiveLink: 'plans-contract',
            breadcrumb: [
                {
                    text: 'Planes',
                    to: '/plans/create'
                },
                {
                    text: 'Contrata tu plan',
                    active: true,
                },
                {
                    text: 'Finalizar',
                    active: true,
                },
            ],
        },
        beforeEnter(to, from, next) {
            const role = store.state.profile.role
            if (role.name === 'Admin' || role.name === 'RealstateAdmin' || role.name === 'RealstateVendor') next()
            else next({ name: 'error-403' })
        },
    }, 
    {
        path: '/plans/list',
        name: 'plans-list',
        component: () => import('@/views/plans/PlansList'),
        meta: {
            requiresAuth: true,
            navActiveLink: 'plans-list',
            breadcrumb: [
                {
                    text: 'Planes',
                    to: '/plans/list'
                },
                {
                    text: 'Proyectos',
                    active: true,
                },
            ],
        },
        beforeEnter(to, from, next) {
            const role = store.state.profile.role
            if (role.name === 'Admin' || role.name === 'RealstateAdmin' || role.name === 'RealstateVendor') next()
            else next({ name: 'error-403' })
        },
    }, 
]
