import gql from 'graphql-tag'

export const FETCH_PROFILE = gql`
    query fetchMe{
        me{
            id
            name
            last_name
            email
            realstateagencyuser{
                id
                realstateagency{
                    id
                    name
                }
            }
            roles{
                name
            }
            permissions{
                name
            }
        }
    }
`

export const FETCH_PERMISSION = gql`
    query userPermission ($id: Int){
        userpermissions(id: $id){
            name
        }
    }
`

export const FETCH_USERSREALSTATEAGENCIES = gql`
    query fetchUsersRealStateAgencies ($id: Int){
        users(id: $id){
            data{
                id
                name
                realstateagencyuser{
                    id
                    realstateagency{
                        id
                        projects{
                            id
                            name
                        }
                    }
                }
            }
        }
    }
`

export const FETCH_REALSTATEAGENCIES_NO_PAGINATION = gql`
    query fetchRealStateAgencies{
        realstateagenciesnopagination{
            data{
                id
                name
                projects{
                    id
                    name
                }
            }
        }
    }
`

export const FETCH_REALSTATEAGENCIES = gql`
    query fetchRealStateAgencies{
        realstateagencies{
            data{
                id
                name
                projects{
                    id
                    name
                }
            }
        }
    }
`

export const FETCH_PROJECTS = gql`
    query fetchProjects{
        projectsrelatedtome {
            id
            name
            crm_id
            realstateagency{
                id
            }
        }
    }
`

export const FETCH_LOCATIONS = gql`
    query fetchLocations{
        regions{
            id
            name
            provinces{
                id
                name
                communes{
                    id
                    name
                }
            }
        }
    }
`

export const EDIT_USER_PROFILE = gql`
    mutation editUserProfile (
        $name: String!,
        $last_name: String!,
        $password: String,
        ) {
        editUserProfile(
            name: $name,
            last_name: $last_name,
            password: $password,
        ) {
            id
            name
            last_name
        }
    }
`

export const FETCH_PROJECT_STATUS = gql`
    query fetchProjectStatus{
        projectstatus{
            id
            name
        }
    }
`

export const FETCH_SUBSIDY_TYPES = gql`
    query fetchSubsidyTypes{
        subsidytypes{
            id
            name
        }
    }
`

export const FETCH_PLAN_ADITTIONAL_TYPES = gql`
    query fetchPlanAdittionalTypes{
        plansadditional{
            id
            name
        }
    }
`

export const FETCH_PROJECT_STAGE_TYPES = gql`
    query fetchProjectStageTypes{
        projectstage{
            id
            name
        }
    }
`  