import graphqlClient from '@/plugins/apollo'
import {FETCH_PERMISSIONS,CREATE_PERMISSIONS,EDIT_PERMISSIONS,DELETE_PERMISSIONS} from '@/services/graphql/permissions'

export async function fetchPermissions (context) {
    return new Promise((resolve, reject) => {
        graphqlClient.query({
            query: FETCH_PERMISSIONS
        }).then(res => {
            context.commit('SET_PERMISSIONS',res.data)
            resolve(res)
        }).catch(error => {
            reject(error)
        })
    })
}

export async function createPermissions (context, permission) {
    return new Promise((resolve, reject) => {
        graphqlClient.mutate({
            mutation: CREATE_PERMISSIONS,
            variables: {
                name: permission.name,
            },
            update: (store, { data: { createPermission } }) => {
                const data = store.readQuery({query: FETCH_PERMISSIONS})
                data.permissions.push(createPermission)
                store.writeQuery({query: FETCH_PERMISSIONS, data})
            },
        }).then(res => {
            resolve(res)
        }).catch(error => {
            reject(error)
        })
    })
}

export async function updatePermissions (context, permission) {
    return new Promise((resolve, reject) => {
        graphqlClient.mutate({
            mutation: EDIT_PERMISSIONS,
            variables: {
                id: permission.id,
                name: permission.name
            },
            update: (store,{ data: {EDIT_PERMISSIONS}}) => {
                const data = store.readQuery({query: FETCH_PERMISSIONS})
                context.commit('SET_PERMISSIONS',data)
                store.writeQuery({query: FETCH_PERMISSIONS, data})
            },
        }).then(res => {
            resolve(res)
        }).catch(error => {
            reject(error)
        })
    })
}

export async function deletePermissions (context, permission) {
    return new Promise((resolve, reject) => {
        graphqlClient.mutate({
            mutation: DELETE_PERMISSIONS,
            variables: {
                name: permission.name
            },
            update: (store,{data: {DELETE_PERMISSIONS}}) => {
                const data = store.readQuery({query: FETCH_PERMISSIONS})
                data.permissions.forEach(function(value, index, object) {
                    if(value.name === permission.name) {
                        object.splice(index, 1)
                    }
                })
                store.writeQuery({query: FETCH_PERMISSIONS, data})
            },
        }).then(res => {
            resolve(res)
        }).catch(error => {
            reject(error)
        })
    })
}
