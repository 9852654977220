import store from "@/store";

export default [
    {
        path: '/comments',
        name: 'comments',
        component: () => import('@/views/comments/Comments.vue'),
        meta: {
            requiresAuth: true,
            navActiveLink: 'comments',
            breadcrumb: [
                {
                    text: 'Comentarios',
                    active: true,
                },
            ],
        },
        beforeEnter(to, from, next) {
            const role = store.state.profile.role
            if (role.name === 'Admin' || role.name === 'SuperAdmin') next()
            else next({ name: 'error-403' })
        },
    },
    {
        path: '/comments/create',
        name: 'comments-create',
        component: () => import('@/views/comments/CommentsCreate'),
        meta: {
            requiresAuth: true,
            navActiveLink: 'comments/create',
            breadcrumb: [
                {
                    text: 'Comentarios',
                    to: '/comments'
                },
                {
                    text: 'Nuevo comentario',
                    active: true,
                },
            ],
        },
        beforeEnter(to, from, next) {
            const role = store.state.profile.role
            if (role.name === 'Admin' || role.name === 'SuperAdmin') next()
            else next({ name: 'error-403' })
        },
    },
    {
        path: '/comments/:id/details',
        name: 'comments-details',
        props: true,
        component: () => import('@/views/comments/CommentsDetails'),
        meta: {
            requiresAuth: true,
            navActiveLink: 'comments',
            breadcrumb: [
                {
                    text: 'Comentarios',
                    to: '/comments'
                },
                {
                    text: 'Detalles',
                    active: true,
                },
            ],
        },
        beforeEnter(to, from, next) {
            const role = store.state.profile.role
            if (role.name === 'Admin' || role.name === 'SuperAdmin') next()
            else next({ name: 'error-403' })
        },
    }, 
]
