export function SET_NEWS (state, data) {
    data.news.data.forEach(element => {
        element.visible = element.status == '1';
    });
    state.news = data.news
}

export function ADD_ONE_NEWS (state, data) {
    var found = false;
    state.news.data.forEach(element => {
        if(element.id == data.createNews.id){

            element.visible = data.createNews.status == '1';
            found = true;
        }
    });
    if(!found){
        state.news.data.unshift(data.createNews);
    }
}

export function UPDATE_ONE_NEWS (state, data) {
    var found = false;
    state.news.data.forEach(element => {
        if(element.id == data.editNews.id){
            element.visible = data.editNews.status == '1';
            found = true;
        }
    });
    if(!found){
        state.news.data.unshift(data.editNews);
    }
}