import gql from 'graphql-tag'

export const FETCH_PERMISSIONS = gql`
    query fetchPermissions{
        permissions{
            id
            name
            audits{
                id
                event
                old_values
                new_values
                created_at
                user{
                    name
                    last_name
                }
            }
        }
    }
`

export const CREATE_PERMISSIONS = gql`
    mutation createPermission($name: String!) {
        createPermission(name: $name) {
            id
            name
            audits{
                id
                event
                old_values
                new_values
                created_at
                user{
                    name
                    last_name
                }
            }
        }
    }
`

export const EDIT_PERMISSIONS = gql`
    mutation EditPermission($id: Int!, $name: String!) {
        editPermission(id: $id, name: $name) {
            id
            name
            audits{
                id
                event
                old_values
                new_values
                created_at
                user{
                    name
                    last_name
                }
            }
        }
    }
`

export const DELETE_PERMISSIONS = gql`
    mutation deletePermission($name: String!) {
        deletePermission(name: $name){
            id
            name
        }
    }
`
