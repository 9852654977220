export function SET_TOKEN (state, data) {
    state.accessToken = data.access_token
    state.refreshToken = data.refresh_token
    state.isLogged = true
}

export function DESTROY_TOKEN (state) {
    state.accessToken = null
    state.refreshToken = null
    state.isLogged = false
}

export function SIGN_OUT (state) {
    state.accessToken = null
    state.refreshToken = null
    state.isLogged = false
}