import Vue from 'vue'
import store from '@/store'

export async function signIn (context, user) {
    return new Promise((resolve, reject) => {
        Vue.axios({
            method: 'POST',
            url: 'oauth/token',
            data: {
                grant_type: process.env.VUE_APP_GRANT_PASSWORD,
                client_id: process.env.VUE_APP_CLIENT_ID,
                client_secret: process.env.VUE_APP_CLIENT_SECRET,
                username: user.email,
                password: user.password,
                scope: process.env.VUE_APP_SCOPE,
            },
        }).then(res => {
            context.commit('SET_TOKEN', res.data)
            resolve(res)
        }).catch(error => {
            reject(error)
        })
    })
}

export async function signOut (context) {
    return new Promise((resolve, reject) => {
        Vue.axios({
            method: 'POST',
            url: 'logout',
            headers: {'Authorization': `Bearer ${store.state.auth.accessToken}`},
        }).then(res => {
            context.commit('SIGN_OUT')
            context.commit('DESTROY_TOKEN')
            store.commit('profile/DESTROY_PROFILE')
            resolve(res)
        }).catch(error => {
            context.commit('SIGN_OUT')
            context.commit('DESTROY_TOKEN')
            store.commit('profile/DESTROY_PROFILE')
            reject(error)
        }).finally(() => {
            context.commit('SIGN_OUT')
            context.commit('DESTROY_TOKEN')
            store.commit('profile/DESTROY_PROFILE')
            top.location.href = top.location.href;//recargamos la pagina para reinicio de roles
        })
    })
}

export async function refreshToken (context) {
    return new Promise((resolve, reject) => {
        Vue.axios({
            method: 'POST',
            url: 'oauth/token',
            headers: {'Authorization': `Bearer ${store.state.auth.accessToken}`},
            data: {
                grant_type: process.env.VUE_APP_GRANT_REFRESH,
                client_id: process.env.VUE_APP_CLIENT_ID,
                client_secret: process.env.VUE_APP_CLIENT_SECRET,
                refresh_token: store.state.auth.refreshToken,
            },
        }).then(res => {
            resolve(res)
        }).catch(error => {
            reject(error)
        })
    })
}