import Vue from 'vue'
import Vuex from 'vuex'
import VuexPersistence from 'vuex-persist'

import app from './modules/base/app'
import appConfig from './modules/base/app-config'
import verticalMenu from './modules/base/vertical-menu'

import auth from './modules/auth'
import profile from './modules/profile'
import dashboard from './modules/dashboard'
import password from './modules/password'
import permissions from './modules/permissions'
import roles from './modules/roles'
import users from './modules/users'
import business from './modules/business'
import quotations from './modules/quotations'
import locations from './modules/locations'
import projects from './modules/projects'
import properties from './modules/properties'
import agencies from './modules/agencies'
import bookings from './modules/bookings'
import promises from './modules/promises'
import preapprovals from './modules/preapprovals'
import news from './modules/news'
import contacts from './modules/contacts'
import plans from './modules/plans'
import tagsNews from './modules/tagsNews'
import comments from './modules/comments'

Vue.use(Vuex)

const vuexLocal = new VuexPersistence({
  storage: window.localStorage,
  modules: ['auth']
})

export default new Vuex.Store({
  modules: {
    app,
    appConfig,
    verticalMenu,
    auth,
    profile,
    dashboard,
    password,
    permissions,
    roles,
    users,
    business,
    quotations,
    locations,
    agencies,
    projects,
    properties,
    bookings,
    promises,
    preapprovals,
    news,
    contacts,
    plans,
    tagsNews,
    comments
  },
  plugins: [vuexLocal.plugin],
  strict: process.env.DEV,
})
