import store from "@/store";

export default [
    {
        path: '/business',
        name: 'business',
        component: () => import('@/views/business/Business'),
        meta: {
            requiresAuth: true,
            navActiveLink: 'business',
            breadcrumb: [
                {
                    text: 'Negocios',
                    active: true,
                },
            ],
        },
        beforeEnter(to, from, next) {
            const role = store.state.profile.role
            if (role.name === 'Admin' || role.name === 'RealstateAdmin' || role.name === 'RealstateUser' || role.name === 'RealstateVendor') next()
            else next({ name: 'error-403' })
        },
    },
    {
        path: '/:id/business-details',
        name: 'business-details',
        props: true,
        component: () => import('@/views/business/BusinessDetails'),
        meta: {
            requiresAuth: true,
            navActiveLink: 'business',
            breadcrumb: [
                {
                    text: 'Negocios',
                    to: '/business'
                },
                {
                    text: 'Detalle negocio',
                    active: true,
                },
            ],
        },
        beforeEnter(to, from, next) {
            const role = store.state.profile.role
            if (role.name === 'Admin' || role.name === 'RealstateAdmin' || role.name === 'RealstateUser' || role.name === 'RealstateVendor') next()
            else next({ name: 'error-403' })
        },
    },
]
