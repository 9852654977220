import gql from 'graphql-tag'

export const FETCH_CONTACTS = gql`
    query fetchContacts ( 
        $real_state_agency: String,
        $project: String, 
        $date_init: String,
        $date_end: String,
        $page: Int
    ){
        contactform( 
            real_state_agency: $real_state_agency,
            project: $project, 
            date_init: $date_init,
            date_end: $date_end,
            page: $page,
        ){
            data{
                id 
                name
                phone
                email
                query
                rut
                project{
                    name
                }
                created_at
                updated_at
            }
            total
            per_page
            current_page
            from
            to
            last_page
            has_more_pages
        }
    }
`

export const FETCH_CONTACTS_NEWSLETTER = gql`
    query fetchContactsNewsletter ( 
        $date_init: String,
        $date_end: String,
        $page: Int
    ){
        contactformnewsletter( 
            date_init: $date_init,
            date_end: $date_end,
            page: $page,
        ){
            data{
                id 
                email
                created_at
                updated_at
            }
            total
            per_page
            current_page
            from
            to
            last_page
            has_more_pages
        }
    }
`

export const FETCH_CONTACTS_ENTERPRISE = gql`
    query fetchContactsEnterprise ( 
        $date_init: String,
        $date_end: String,
        $page: Int
    ){
        contactformenterprise( 
            date_init: $date_init,
            date_end: $date_end,
            page: $page,
        ){
            data{
                id 
                first_name
                last_name
                email
                phone
                enterprise
                message
                created_at
                updated_at
            }
            total
            per_page
            current_page
            from
            to
            last_page
            has_more_pages
        }
    }
`

export const FETCH_CONTACTS_FORM_SECTION = gql`
    query fetchContactsFormSection ( 
        $date_init: String,
        $date_end: String,
        $page: Int
    ){
        contactformsection( 
            date_init: $date_init,
            date_end: $date_end,
            page: $page,
        ){
            data{
                id 
                name
                email
                message
                created_at
                updated_at
            }
            total
            per_page
            current_page
            from
            to
            last_page
            has_more_pages
        }
    }
`

export const FETCH_CONTACTS_FORM_HOME_SECTION = gql`
    query fetchContactsFormHomeSection ( 
        $date_init: String,
        $date_end: String,
        $page: Int
    ){
        contactformhomesection( 
            date_init: $date_init,
            date_end: $date_end,
            page: $page,
        ){
            data{
                id 
                name
                phone
                email
                rut
                method_contact
                salary
                region{
                    name
                }
                created_at
                updated_at
            }
            total
            per_page
            current_page
            from
            to
            last_page
            has_more_pages
        }
    }
`

export const EXPORT_CONTACTS = gql`
mutation exportContacts(
    $real_state_agency: String,
    $project: String,
    $type: String,
    $date_init: String,
    $date_end: String
){
    requestContactsMutation(
        real_state_agency: $real_state_agency,
        project: $project,
        type: $type,
        date_init: $date_init,
        date_end: $date_end
    ){
        id
    }
}
`

export const EXPORT_CONTACTS_NEWSLETTER = gql`
mutation exportContacts(
    $date_init: String,
    $date_end: String
){
    requestContactsNewsletterMutation(
        date_init: $date_init,
        date_end: $date_end
    ){
        id
    }
}
`

export const EXPORT_CONTACTS_ENTERPRISE = gql`
mutation exportContacts(
    $date_init: String,
    $date_end: String
){
    requestContactsEnterpriseMutation(
        date_init: $date_init,
        date_end: $date_end
    ){
        id
    }
}
`

export const EXPORT_CONTACTS_FORM = gql`
mutation exportContacts(
    $date_init: String,
    $date_end: String
){
    requestContactsFormMutation(
        date_init: $date_init,
        date_end: $date_end
    ){
        id
    }
}
`

export const EXPORT_CONTACTS_FORM_HOME = gql`
mutation exportContacts(
    $date_init: String,
    $date_end: String
){
    requestContactsFormHomeMutation(
        date_init: $date_init,
        date_end: $date_end
    ){
        id
    }
}
`