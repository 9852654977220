import graphqlClient from '@/plugins/apollo'
import {FETCH_CONTACTS,
        EXPORT_CONTACTS,
        FETCH_CONTACTS_NEWSLETTER,
        FETCH_CONTACTS_ENTERPRISE,
        FETCH_CONTACTS_FORM_SECTION,
        EXPORT_CONTACTS_NEWSLETTER,
        EXPORT_CONTACTS_ENTERPRISE,
        EXPORT_CONTACTS_FORM,
        EXPORT_CONTACTS_FORM_HOME,
        FETCH_CONTACTS_FORM_HOME_SECTION
    } from '@/services/graphql/contacts'

export async function fetchContacts (context, filter) { 

    if(filter != undefined){  
        var page = "";if(filter.page != ""){page = filter.page} 
        var real_state_agency = "";if(filter.real_state_agency != ""){real_state_agency = filter.real_state_agency}
        var project = "";if(filter.project != ""){project = filter.project} 
        var date_init = "";if(filter.date_init != ""){date_init = filter.date_init}
        var date_end = "";if(filter.date_end != ""){date_end = filter.date_end}
    }
    return new Promise((resolve, reject) => {
        var variables = {
            page: page,
            real_state_agency:real_state_agency.toString(),
            project:project,
            date_init:date_init,
            date_end:date_end,
        };
        graphqlClient.query({
            query: FETCH_CONTACTS,
            variables: variables
        }).then(res => {
            context.commit('SET_CONTACTS',res.data)
            resolve(res)
        }).catch(error => {
            reject(error)
        })
    })
}

export async function fetchContactsNewsletter (context, filter) { 

    if(filter != undefined){  
        var page = "";if(filter.page != ""){page = filter.page} 
        var date_init = "";if(filter.date_init != ""){date_init = filter.date_init}
        var date_end = "";if(filter.date_end != ""){date_end = filter.date_end}
    }
    return new Promise((resolve, reject) => {
        var variables = {
            page: page,
            date_init:date_init,
            date_end:date_end,
        };
        graphqlClient.query({
            query: FETCH_CONTACTS_NEWSLETTER,
            variables: variables
        }).then(res => {
            context.commit('SET_CONTACTS_NEWSLETTER',res.data)
            resolve(res)
        }).catch(error => {
            reject(error)
        })
    })
}

export async function fetchContactsEnterprise (context, filter) { 

    if(filter != undefined){  
        var page = "";if(filter.page != ""){page = filter.page} 
        var date_init = "";if(filter.date_init != ""){date_init = filter.date_init}
        var date_end = "";if(filter.date_end != ""){date_end = filter.date_end}
    }
    return new Promise((resolve, reject) => {
        var variables = {
            page: page,
            date_init:date_init,
            date_end:date_end,
        };
        graphqlClient.query({
            query: FETCH_CONTACTS_ENTERPRISE,
            variables: variables
        }).then(res => {
            context.commit('SET_CONTACTS_ENTERPRISE',res.data)
            resolve(res)
        }).catch(error => {
            reject(error)
        })
    })
}

export async function fetchContactsFormSection (context, filter) { 

    if(filter != undefined){  
        var page = "";if(filter.page != ""){page = filter.page} 
        var date_init = "";if(filter.date_init != ""){date_init = filter.date_init}
        var date_end = "";if(filter.date_end != ""){date_end = filter.date_end}
    }
    return new Promise((resolve, reject) => {
        var variables = {
            page: page,
            date_init:date_init,
            date_end:date_end,
        };
        graphqlClient.query({
            query: FETCH_CONTACTS_FORM_SECTION,
            variables: variables
        }).then(res => {
            context.commit('SET_CONTACTS_FORM_SECTION',res.data)
            resolve(res)
        }).catch(error => {
            reject(error)
        })
    })
}

export async function fetchContactsFormHomeSection (context, filter) { 

    if(filter != undefined){  
        var page = "";if(filter.page != ""){page = filter.page} 
        var date_init = "";if(filter.date_init != ""){date_init = filter.date_init}
        var date_end = "";if(filter.date_end != ""){date_end = filter.date_end}
    }
    return new Promise((resolve, reject) => {
        var variables = {
            page: page,
            date_init:date_init,
            date_end:date_end,
        };
        graphqlClient.query({
            query: FETCH_CONTACTS_FORM_HOME_SECTION,
            variables: variables
        }).then(res => {
            context.commit('SET_CONTACTS_FORM_HOME_SECTION',res.data)
            resolve(res)
        }).catch(error => {
            reject(error)
        })
    })
}

export async function exportContacts (context, filter) {

    var real_state_agency = "";if(filter.real_state_agency != ""){real_state_agency = filter.real_state_agency}
    var project = "";if(filter.project != ""){project = filter.project}
    var type = "";if(filter.type != ""){type = filter.type}
    var date_init = "";if(filter.date_init != ""){date_init = filter.date_init}
    var date_end = "";if(filter.date_end != ""){date_end = filter.date_end}
    
    return new Promise((resolve, reject) => {
        graphqlClient.mutate({
            mutation: EXPORT_CONTACTS,
            variables: {
                real_state_agency:real_state_agency.toString(),
                project:project.toString(),
                type:type,
                date_init:date_init,
                date_end:date_end,
            },
        }).then(res => {
            resolve(res)
        }).catch(error => {
            reject(error)
        })
    })
}

export async function exportContactsNewsletter (context, filter) {

    var date_init = "";if(filter.date_init != ""){date_init = filter.date_init}
    var date_end = "";if(filter.date_end != ""){date_end = filter.date_end}
    
    return new Promise((resolve, reject) => {
        graphqlClient.mutate({
            mutation: EXPORT_CONTACTS_NEWSLETTER,
            variables: {
                date_init:date_init,
                date_end:date_end,
            },
        }).then(res => {
            resolve(res)
        }).catch(error => {
            reject(error)
        })
    })
}

export async function exportContactsEnterprise (context, filter) {

    var date_init = "";if(filter.date_init != ""){date_init = filter.date_init}
    var date_end = "";if(filter.date_end != ""){date_end = filter.date_end}
    
    return new Promise((resolve, reject) => {
        graphqlClient.mutate({
            mutation: EXPORT_CONTACTS_ENTERPRISE,
            variables: {
                date_init:date_init,
                date_end:date_end,
            },
        }).then(res => {
            resolve(res)
        }).catch(error => {
            reject(error)
        })
    })
}

export async function exportContactsForm (context, filter) {

    var date_init = "";if(filter.date_init != ""){date_init = filter.date_init}
    var date_end = "";if(filter.date_end != ""){date_end = filter.date_end}
    
    return new Promise((resolve, reject) => {
        graphqlClient.mutate({
            mutation: EXPORT_CONTACTS_FORM,
            variables: {
                date_init:date_init,
                date_end:date_end,
            },
        }).then(res => {
            resolve(res)
        }).catch(error => {
            reject(error)
        })
    })
}

export async function exportContactsFormHome (context, filter) {

    var date_init = "";if(filter.date_init != ""){date_init = filter.date_init}
    var date_end = "";if(filter.date_end != ""){date_end = filter.date_end}
    
    return new Promise((resolve, reject) => {
        graphqlClient.mutate({
            mutation: EXPORT_CONTACTS_FORM_HOME,
            variables: {
                date_init:date_init,
                date_end:date_end,
            },
        }).then(res => {
            resolve(res)
        }).catch(error => {
            reject(error)
        })
    })
}