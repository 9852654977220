import gql from 'graphql-tag'

export const FETCH_BUSINESS = gql`
    query fetchBusiness(
      $customer_name_rut_email_phone: String
      $project_id: Int,
      $date_ini: String,
      $date_end: String,
      $rukans_vig: String,
      $pre_approvals_status: String,
      $page: Int
    ){
        prospects (customer_name_rut_email_phone: $customer_name_rut_email_phone, date_ini: $date_ini, date_end: $date_end, project_id: $project_id, rukans_vig: $rukans_vig, pre_approvals_status: $pre_approvals_status, page: $page){
            data{
                id
                created_at
                criticality
                status
                description 
                pipelinestage{
                    id
                    name
                    order
                    permanent_stage
                    pipeline{
                        id
                        name
                    }
                },
                customer{
                    id
                    name
                    last_name
                    rut
                    rut_dv
                    email
                    phone
                    status
                    subsidio
                    rukaneado{
                      id
                      status
                    }
                },
                prospectapproval{
                    id
                    preapproval{
                        id
                        created_at
                        status
                        amount_property
                        amount_foot
                        credit_term
                        customer{
                            id
                            codeudor{
                                name
                            }
                        }
                        project{
                            id
                            name
                            description
                            slug
                            address
                            latitude
                            longitude
                            type_property
                            
                            realstateagency{
                                id
                                name
                            }
                        }
                    }
                },
                prospectquotation{
                    id
                    project{
                        id
                        name
                        slug
                        address
                        latitude
                        longitude
                        type_property
                        
                }
                    quotation{
                        id
                        created_at
                        minimum_rent
                        maximum_rent
                        project{
                    id
                    name
                    property{
                      id
                      identifier
                      price
                    }
                  }
                        bookingrequest{
                            id
                            status
                            created_at
                            bookingorder{
                                id
                                status
                                amount_paid
                                uf_value
                                order_number
                                datetime_transaction
                                gateway_reference
                                signature
                            }
                        }
                        promiserequest{
                            id
                            status
                            amount_paid
                            uf_value
                            order_number
                            datetime_transaction
                            gateway_reference
                            signature
                            promiseorder{
                              id
                              status
                              created_at
                            }
                        }
                    }
                }
                rukan{
                    id
                    status
                    created_at
                    rukanholder{
                        id_holder
                        rut_holder
                        name
                        sex
                        marital_status
                        country
                        date_birth
                        date_death
                        disability
                    }
                    rukanpartner{
                        id_partner
                        rut_partner
                        name
                        sex
                        marital_status
                        country
                        date_birth
                        date_death
                        disability
                    }
                    rukanson{
                        id_son
                        rut_son
                        name
                        sex
                        marital_status
                        country
                        date_birth
                        date_death
                        disability
                    }
                    holdersocialregistry{
                        id_registry_holder
                        tramo_cse
                        folio
                        date_poll
                        region
                        city
                    }
                    membersocialregistry{
                        id_registry_member
                        rut_member
                        name_member
                        relationship
                        member_date_birth
                        is_postulating
                        booking_projects
                        affected
                        military_service
                    }
                    residencedata{
                        id_residence
                        bedrooms
                        overcrowding
                        type_residence
                        water_distribution
                        water_system
                        walls
                        floors
                        ceilings
                        conservation_status
                    }
                    ruralhousingprogram{
                        id
                        deficit_hability
                    }
                    holdersubsidy{
                        id_holder_subsidy
                        rut
                        quality
                        program
                        offer
                        year_subsidy
                        region
                        province
                        city
                        state_subsidy
                        state_paid
                    }
                    partnersubsidy{
                        id_partner_subsidy
                        rut
                        quality
                        program
                        offer
                        year_subsidy
                        region
                        province
                        city
                        state_subsidy
                        state_paid
                    }
                    membersubsidy{
                        id_member_subsidy
                        rut
                        quality
                        program
                        offer
                        year_subsidy
                        region
                        province
                        city
                        state_subsidy
                        state_paid
                    }
                    holderproperty{
                        id
                        rut
                        region
                        province
                        commune
                        destination
                    }
                    partnerproperty{
                        id
                        rut
                        region
                        province
                        commune
                        destination
                    }
                }
            }
            total
            per_page
            current_page
            from
            to
            last_page
            has_more_pages
        }
    }
`

export const FETCH_BUSINESS_PER_TYPE = gql`
    query fetchBusinesspertype(
      $customer_name_rut_email_phone: String
      $project_id: Int,
      $date_ini: String,
      $date_end: String,
      $rukans_vig: String,
      $pre_approvals_status: String,
      $subquery: String,
      $subpage: String,
      $page: Int,
      $incremental: String,
    ){
      prospectspertypequery (incremental: $incremental,customer_name_rut_email_phone: $customer_name_rut_email_phone, date_ini: $date_ini, date_end: $date_end, project_id: $project_id,
        subquery: $subquery,
        subpage: $subpage,
        rukans_vig: $rukans_vig, pre_approvals_status: $pre_approvals_status, page: $page
        ){
            paginate_totals{
              id
              info
              data
            },
            data{
                id
                created_at
                criticality
                status
                description
                pipelinestage{
                    id
                    name
                    order
                    permanent_stage
                    pipeline{
                        id
                        name
                    }
                },
                customer{
                    id
                    name
                    last_name
                    rut
                    rut_dv
                    email
                    phone
                    status
                    rukaneado{
                      id
                      status
                    }
                },
                prospectapproval{
                    id
                    preapproval{
                        id
                        created_at
                        status
                        amount_property
                        amount_foot
                        credit_term
                        customer{
                            id
                            codeudor{
                                name
                            }
                        }
                        project{
                            id
                            name
                            description
                            slug
                            address
                            latitude
                            longitude
                            type_property
                            
                            realstateagency{
                                id
                                name
                            }
                        }
                    }
                },
                prospectquotation{
                    id
                    project{
                        id
                        name
                        slug
                        address
                        latitude
                        longitude
                        type_property
                        
                }
                    quotation{
                        id
                        created_at
                        minimum_rent
                        maximum_rent
                        project{
                    id
                    name
                    property{
                      id
                      identifier
                      price
                    }
                  }
                        bookingrequest{
                            id
                            status
                            created_at
                            bookingorder{
                                id
                                status
                                amount_paid
                                uf_value
                                order_number
                                datetime_transaction
                                gateway_reference
                                signature
                            }
                        }
                        promiserequest{
                            id
                            status
                            amount_paid
                            uf_value
                            order_number
                            datetime_transaction
                            gateway_reference
                            signature
                            promiseorder{
                              id
                              status
                              created_at
                            }
                        }
                    }
                }
                rukan{
                    id
                    status
                    created_at
                    rukanholder{
                        id_holder
                        rut_holder
                        name
                        sex
                        marital_status
                        country
                        date_birth
                        date_death
                        disability
                    }
                    rukanpartner{
                        id_partner
                        rut_partner
                        name
                        sex
                        marital_status
                        country
                        date_birth
                        date_death
                        disability
                    }
                    rukanson{
                        id_son
                        rut_son
                        name
                        sex
                        marital_status
                        country
                        date_birth
                        date_death
                        disability
                    }
                    holdersocialregistry{
                        id_registry_holder
                        tramo_cse
                        folio
                        date_poll
                        region
                        city
                    }
                    membersocialregistry{
                        id_registry_member
                        rut_member
                        name_member
                        relationship
                        member_date_birth
                        is_postulating
                        booking_projects
                        affected
                        military_service
                    }
                    residencedata{
                        id_residence
                        bedrooms
                        overcrowding
                        type_residence
                        water_distribution
                        water_system
                        walls
                        floors
                        ceilings
                        conservation_status
                    }
                    ruralhousingprogram{
                        id
                        deficit_hability
                    }
                    holdersubsidy{
                        id_holder_subsidy
                        rut
                        quality
                        program
                        offer
                        year_subsidy
                        region
                        province
                        city
                        state_subsidy
                        state_paid
                    }
                    partnersubsidy{
                        id_partner_subsidy
                        rut
                        quality
                        program
                        offer
                        year_subsidy
                        region
                        province
                        city
                        state_subsidy
                        state_paid
                    }
                    membersubsidy{
                        id_member_subsidy
                        rut
                        quality
                        program
                        offer
                        year_subsidy
                        region
                        province
                        city
                        state_subsidy
                        state_paid
                    }
                    holderproperty{
                        id
                        rut
                        region
                        province
                        commune
                        destination
                    }
                    partnerproperty{
                        id
                        rut
                        region
                        province
                        commune
                        destination
                    }
                }
            }
        }
    }
`

export const FETCH_PIPELINES = gql`
query fetchPipelines{
  pipelines{
    pipelinestages{
      id
      name
    }
  }
}
`

export const EXPORT_BUSINESS = gql`
mutation exportBusiness(
  $date_ini: String,
  $date_end: String,
  $project_id: String,
  $pre_approvals_status: String,
  $rukan: String,
  ) {
  requestProspectFile(
    date_ini: $date_ini,
    date_end: $date_end,
    project_id: $project_id, 
    pre_approvals_status: $pre_approvals_status,
    rukans_vig: $rukan,
  )
  {
    id
  }
}
`

export const CHANGE_STATE = gql`
mutation changeState(
  $prospect_id: Int, 
  $description: String,
  $pipeline_stage_id: Int,
  ) {
  editProspect(
    prospect_id: $prospect_id,
    description: $description,
    pipeline_stage_id: $pipeline_stage_id,
  )
  {
    id
    created_at
    criticality
    status
    description
    rukan{
      id 
      created_at
      customer{
        id
        name
        last_name
        rut
        rut_dv
        email
        phone
        status
        rukaneado{
          id
          status
        }
      }
    },
      pipelinestage{
      id
      name
      order
      permanent_stage
      pipeline{
        id
        name
      }
    },
    customer{
        id
        name
        last_name
        rut
        rut_dv
        email
        phone
        status
        rukaneado{
          id
          status
        }
    },
    prospectapproval{
      id
      preapproval{
        id
        created_at
        status
        amount_property
        amount_foot
        credit_term
        customer{
          id
          codeudor{
            name
          }
        }
        project{
          id
          name
          description
          slug
          address
          latitude
          longitude
          type_property
          subsidies{
              subsidy{
                  id
                  name
              }
          }
          realstateagency{
            id
            name
          }
        }
      }
    },
    prospectquotation{
      id
      project{
        id
        name
        slug
        address
        latitude
        longitude
        type_property
        subsidies{
            subsidy{
                id
                name
            }
        }
        realstateagency{
          id
          name
        }
      }
      quotation{
        id
        created_at
        minimum_rent
        maximum_rent
        project{
          id
          name
          property{
            id
            identifier
            price
          }
        }
        bookingrequest{
          id
          status
          created_at
          bookingorder{
            id
            status
            amount_paid
            uf_value
            order_number
            datetime_transaction
            gateway_reference
            signature
          }
        }
        promiserequest{
          id
          status
          amount_paid
          uf_value
          order_number
          datetime_transaction
          gateway_reference
          signature
          promiseorder{
            id
            status
            created_at
          }
        }
      }
    }
  }
}
`