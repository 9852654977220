import gql from 'graphql-tag'

export const FETCH_QUOTATIONS = gql`
    query fetchQuotations(
        $project_id: String,
        $date_ini: String,
        $date_end: String,
        $real_state_agency: String,
        $page: Int
    ){
        quotations (
            date_ini: $date_ini,
            date_end: $date_end,
            project_id: $project_id,
            real_state_agency: $real_state_agency,
            page: $page
        ){
            data{
                id
                created_at
                customer{
                    id
                    email
                    name
                    last_name
                    phone
                    rut
                    rut_dv
                    subsidio
                    commune{
                        id
                        name
                    }
                }
                audits{
                    id
                    event
                    old_values
                    new_values
                    created_at
                    user{
                        name
                        last_name
                    }
                }
                propertyquotation{
                    id
                    price
                    property{
                        id
                        identifier
                        model
                        price
                        floor
                        bedrooms
                        bathrooms
                        tower
                        bookeable
                        promisable
                        total_area
                        municipal_area
                        built_area
                        terrace_area
                        visible
                        project{
                            id
                            name
                            commune{
                                id
                                name
                            }
                        }
                    }
                    warehouse{
                        id
                        identifier
                        price
                        floor
                        tower
                        total_area
                        municipal_area
                        built_area
                        visible
                    }
                    parkinglot{
                        id
                        identifier
                        price
                        floor
                        tower
                        total_area
                        municipal_area
                        built_area
                        visible
                    }
                }
            }
            total
            per_page
            current_page
            from
            to
            last_page
            has_more_pages
        }
    }
`

export const EXPORT_QUOTATIONS = gql`
    mutation exportQuotations(
        $customer_name: String,
        $customer_email: String,
        $date_ini: String,
        $date_end: String,
        $project_id: Int,
        $unit_id: String,
        $real_state_agency: String,
        ) {
        requestQuotationsFile(
            real_state_agency: $real_state_agency,
            customer_name: $customer_name,
            customer_email: $customer_email,
            date_ini: $date_ini,
            date_end: $date_end,
            project_id: $project_id,
            unit_id: $unit_id,
        ) {
            id
        }
    }
`

export const FETCH_CONSOLIDITY = gql`
    query fetchConsolidity(
        $date_ini: String,
        $date_end: String,
    ){
        consolidity (
            date_ini: $date_ini,
            date_end: $date_end,
        ){
            origin
            quotations
            contacts
            total
            percentage
        }
    }
`
