export default {
    profile: {},
    role: {},
    permission: [],
    status: false,
    realStateAgencies: [],
    usersRealStateAgencies: [],
    profileProjects: [],
    locations: [],
    projectstatus:[],
    subsidytypes:[]
}
