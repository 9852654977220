import store from '@/store'

export default [
    {
        path: '/projects',
        name: 'projects',
        component: () => import('@/views/projects/Projects'),
        meta: {
            requiresAuth: true,
            navActiveLink: 'projects',
            breadcrumb: [
                {
                    text: 'Proyectos',
                    active: true,
                },
            ],
        },
        beforeEnter(to, from, next) {
            const role = store.state.profile.role
            const status = store.state.profile.status

            if (!status || (status && (role.name === 'Admin' || role.name === 'RealstateAdmin' || role.name === 'RealstateUser' || role.name === 'RealstateVendor'))) next()
            else next({ name: 'error-403' })
        },
    },
    {
        path: '/projects/create',
        name: 'projects-create',
        component: () => import('@/views/projects/ProjectsCreate'),
        meta: {
            requiresAuth: true,
            navActiveLink: 'projects',
            breadcrumb: [
                {
                    text: 'Proyectos',
                    to: '/projects'
                },
                {
                    text: 'Crear Proyecto',
                    active: true,
                },
            ],
        },
        beforeEnter(to, from, next) {
            const role = store.state.profile.role
            if (role.name === 'Admin' || role.name === 'RealstateAdmin' || role.name === 'RealstateVendor') next()
            else next({ name: 'error-403' })
        },
    },
    {
        path: '/projects/:id/edit',
        name: 'projects-edit',
        props: true,
        component: () => import('@/views/projects/ProjectsEdit'),
        meta: {
            requiresAuth: true,
            navActiveLink: 'projects',
            breadcrumb: [
                {
                    text: 'Proyectos',
                    to: '/projects'
                },
                {
                    text: 'Editar Proyecto',
                    active: true,
                },
            ],
        },
        beforeEnter(to, from, next) {
            const role = store.state.profile.role
            if (role.name === 'Admin' || role.name === 'RealstateAdmin' || role.name === 'RealstateVendor') next()
            else next({ name: 'error-403' })
        },
    },
    {
        path: '/properties/:id/audits',
        name: 'properties-audits',
        props: true,
        component: () => import('@/views/properties/PropertiesAudits'),
        meta: {
            requiresAuth: true,
            navActiveLink: 'properties',
            breadcrumb: [
                {
                    text: 'Proyectos',
                    to: '/projects'
                },
                {
                    text: 'Unidades',
                    to: '/properties'
                },
                {
                    text: 'Auditoria',
                    active: true,
                },
            ],
        },
        beforeEnter(to, from, next) {
            const role = store.state.profile.role
            if (role.name === 'Admin') next()
            else next({ name: 'error-403' })
        },
    },
    {
        path: '/projects/:id/audits',
        name: 'projects-audits',
        props: true,
        component: () => import('@/views/projects/ProjectsAudits'),
        meta: {
            requiresAuth: true,
            navActiveLink: 'projects',
            breadcrumb: [
                {
                    text: 'Proyectos',
                    to: '/projects'
                },
                {
                    text: 'Auditoria',
                    active: true,
                },
            ],
        },
        beforeEnter(to, from, next) {
            const role = store.state.profile.role
            if (role.name === 'Admin' || role.name === 'RealstateAdmin' || role.name === 'RealstateVendor') next()
            else next({ name: 'error-403' })
        },
    },
    {
        path: '/projects/:id/webhooks',
        name: 'projects-webhooks',
        props: true,
        component: () => import('@/views/projects/ProjectsWebhooks'),
        meta: {
            requiresAuth: true,
            navActiveLink: 'projects',
            breadcrumb: [
                {
                    text: 'Proyectos',
                    to: '/projects'
                },
                {
                    text: 'Webhooks',
                    active: true,
                },
            ],
        },
        beforeEnter(to, from, next) {
            const role = store.state.profile.role
            if (role.name === 'Admin') next()
            else next({ name: 'error-403' })
        },
    },
    {
        path: '/projects/:id/webhooks/audits',
        name: 'projects-webhooks-audits',
        props: true,
        component: () => import('@/views/projects/ProjectsWebhooksAudits'),
        meta: {
            requiresAuth: true,
            navActiveLink: 'projects',
            breadcrumb: [
                {
                    text: 'Proyectos',
                    to: '/projects'
                },
                {
                    text: 'Webhooks',
                    active: true,
                },
                {
                    text: 'Auditoria',
                    active: true,
                },
            ],
        },
        beforeEnter(to, from, next) {
            const role = store.state.profile.role
            if (role.name === 'Admin') next()
            else next({ name: 'error-403' })
        },
    },
    {
        path: '/properties',
        name: 'properties',
        component: () => import('@/views/properties/Properties'),
        meta: {
            requiresAuth: true,
            navActiveLink: 'properties',
            breadcrumb: [
                {
                    text: 'Proyectos',
                    to: '/projects'
                },
                {
                    text: 'Unidades',
                    active: true,
                },
            ],
        },
        beforeEnter(to, from, next) {
            const role = store.state.profile.role
            const status = store.state.profile.status

            if (!status || (status && (role.name === 'Admin' || role.name === 'RealstateAdmin' || role.name === 'RealstateUser' || role.name === 'RealstateVendor'))) next()
            else next({ name: 'error-403' })
        },
    },
    {
        path: '/properties/:id/details',
        name: 'properties-details',
        props: true,
        component: () => import('@/views/properties/PropertiesDetails'),
        meta: {
            requiresAuth: true,
            navActiveLink: 'properties',
            breadcrumb: [
                {
                    text: 'Proyectos',
                    to: '/projects'
                },
                {
                    text: 'Unidades',
                    to: '/properties'
                },
                {
                    text: 'Detalles',
                    active: true,
                },
            ],
        },
        beforeEnter(to, from, next) {
            const role = store.state.profile.role
            if (role.name === 'Admin' || role.name === 'RealstateAdmin' || role.name === 'RealstateUser' || role.name === 'RealstateVendor') next()
            else next({ name: 'error-403' })
        },
    },
    {
        path: '/parkinglots/:id/details',
        name: 'parkinglots-details',
        props: true,
        component: () => import('@/views/properties/ParkinglotsDetails'),
        meta: {
            requiresAuth: true,
            navActiveLink: 'properties',
            breadcrumb: [
                {
                    text: 'Proyectos',
                    to: '/projects'
                },
                {
                    text: 'Unidades',
                    to: '/properties'
                },
                {
                    text: 'Detalles estacionamiento',
                    active: true,
                },
            ],
        },
        beforeEnter(to, from, next) {
            const role = store.state.profile.role
            if (role.name === 'Admin' || role.name === 'RealstateAdmin' || role.name === 'RealstateUser' || role.name === 'RealstateVendor') next()
            else next({ name: 'error-403' })
        },
    },
    {
        path: '/warehouses/:id/details',
        name: 'warehouses-details',
        props: true,
        component: () => import('@/views/properties/WarehousesDetails'),
        meta: {
            requiresAuth: true,
            navActiveLink: 'properties',
            breadcrumb: [
                {
                    text: 'Proyectos',
                    to: '/projects'
                },
                {
                    text: 'Unidades',
                    to: '/properties'
                },
                {
                    text: 'Detalles bodega',
                    active: true,
                },
            ],
        },
        beforeEnter(to, from, next) {
            const role = store.state.profile.role
            if (role.name === 'Admin' || role.name === 'RealstateAdmin' || role.name === 'RealstateUser' || role.name === 'RealstateVendor') next()
            else next({ name: 'error-403' })
        },
    },
]
