import store from '@/store'

export default [
    {
        path: '/home',
        name: 'home',
        component: () => import('@/views/dashboard/Home'),
        meta: {
            requiresAuth: true,
            role: true,
            navActiveLink: 'home',
            breadcrumb: [
                {
                    text: 'Inicio',
                    active: true,
                },
            ],
        },
        beforeEnter(to, from, next) {
            const role = store.state.profile.role
            if (role.name !== 'PreapprovalUser') next()
            else next({ name: 'error-403' })
        },
    },
]
