import Pusher from 'pusher-js';

const pusher = new Pusher(process.env.VUE_APP_PUSHER_KEY, {
    cluster: process.env.VUE_APP_CLUSTER,
});

//configurar canales y eventos
//const channel = pusher.subscribe('my-channel');
//channel.bind('my-event', function (data) {
    //console.log(data);
//});