import gql from 'graphql-tag'

export const FETCH_NEWS = gql`
    query fetchNews ( 
        $page: Int
        $title: String
        $date: String
        $status: String
    ){
        news( 
            page: $page,
            title: $title,
            date: $date,
            status: $status, 
        ){
            data{
                id
                title
                body
                date
                user_id
                status
                subtitle
                source
                medias{
                    id
                    mime
                    full_url
                    extension
                    mediatype {
                        id
                        name
                    }
                }
                body_footer
                created_at
                updated_at
                tagsNews {
                    id
                    name
                }
                projects {
                    id
                    name
                }
            }
            total
            per_page
            current_page
            from
            to
            last_page
            has_more_pages
        }
    }
`
 

export const EXPORT_NEWS = gql`
    mutation exportPreapprovals(
        $project_id: Int,
        $status: String,
        ) {
        requestApprovalFile(
            project_id: $project_id,
            status: $status,
        ) {
            id
        }
    }
`

export const CREATE_NEWS = gql`
    mutation createNews(
        $title: String, 
        $body: String,
        $date: String,
        $user_id: String,
        $status: String,
        $subtitle: String,
        $source: String,
        $body_footer: String,
        $logo: Upload,
        $cover_page: Upload
    ) {
        createNews( 
            title: $title,
            body: $body,
            date: $date,
            user_id: $user_id,
            status: $status,
            subtitle: $subtitle,
            source: $source,
            body_footer: $body_footer,
            logo: $logo,
            cover_page: $cover_page
        ) {
            id
            title
            body
            date
            user_id
            status
            subtitle
            source
            medias{
                id
                mime
                full_url
                extension
                mediatype {
                    id
                    name
                }
            }
            body_footer
            created_at
            updated_at
        }
    }
`

export const CHANGE_NEWS = gql`
    mutation editStatusNews(
        $new_id: String,
        $status: String
    ) {
        editStatusNews(
            new_id: $new_id,
            status: $status
        ) {
            id
            title
            body
            date
            user_id
            status
            subtitle
            source
            medias{
                id
                mime
                full_url
                extension
                mediatype {
                    id
                    name
                }
            }
            body_footer
            created_at
            updated_at
        }
    }
`

export const EDIT_NEWS = gql`
    mutation editNews(
        $news_id: String!,
        $title: String, 
        $body: String,
        $date: String,
        $user_id: String,
        $status: String,
        $subtitle: String,
        $source: String,
        $body_footer: String,
        $logo: Upload,
        $cover_page: Upload,
        $tagsNews: [Int],
        $projectsNews: [Int]
    ) {
        editNews(
            news_id: $news_id,
            title: $title,
            body: $body,
            date: $date,
            user_id: $user_id,
            status: $status,
            subtitle: $subtitle,
            source: $source,
            body_footer: $body_footer,
            logo: $logo,
            cover_page: $cover_page,
            tagsNews: $tagsNews,
            projectsNews: $projectsNews
        ) {
            id
            title
            body
            date
            user_id
            status
            subtitle
            source
            medias{
                id
                mime
                full_url
                extension
                mediatype {
                    id
                    name
                }
            }
            tagsNews {
                id
                name
            }
            projects {
                id
                name
            }
            body_footer
            created_at
            updated_at
        }
    }
`