import Vue from 'vue'
import store from '@/store'
import graphqlClient from '@/plugins/apollo'
import {FETCH_PROMISES_ORDERS,FETCH_PROMISES_REQUESTS, EDIT_PROMISE_ORDERS, EDIT_PROMISE_REQUESTS , EXPORT_PROMISE_ORDER_FILE , EXPORT_PROMISE_REQUEST_FILE} from '@/services/graphql/promises'

export async function fetchPromisesOrders (context, filter) {
    return new Promise((resolve, reject) => {
        graphqlClient.query({
            query: FETCH_PROMISES_ORDERS,
            variables: {
                project_id: filter.project,
                status: filter.status,
                page: filter.page
            }
        }).then(res => {
            context.commit('SET_PROMISES_ORDERS',res.data)
            resolve(res)
        }).catch(error => {
            reject(error)
        })
    })
}

export async function updatePromiseOrder (context, promiseOrder) {
    return new Promise((resolve, reject) => {
        graphqlClient.mutate({
            mutation: EDIT_PROMISE_ORDERS,
            variables: {
                promiseorder_id: promiseOrder.id,
                status: promiseOrder.name,
            },
            update: (store,{data: {EDIT_PROMISE_ORDERS}}) => {
                const data = store.readQuery({query: FETCH_PROMISES_ORDERS})
                context.commit('SET_PROMISES_ORDERS',data)
                store.writeQuery({query: FETCH_PROMISES_ORDERS, data})
            },
        }).then(res => {
            resolve(res)
        }).catch(error => {
            reject(error)
        })
    })
}

export async function fetchPromisesRequests (context, filter) {
    return new Promise((resolve, reject) => {
        graphqlClient.query({
            query: FETCH_PROMISES_REQUESTS,
            variables: {
                project_id: filter.project,
                status: filter.status,
                page: filter.page
            }
        }).then(res => {
            context.commit('SET_PROMISES_REQUESTS',res.data)
            resolve(res)
        }).catch(error => {
            reject(error)
        })
    })
}

export async function updatePromiseRequest (context, promiseRequest) {
    return new Promise((resolve, reject) => {
        graphqlClient.mutate({
            mutation: EDIT_PROMISE_REQUESTS,
            variables: {
                promiserequest_id: promiseRequest.id,
                status: promiseRequest.name,
            },
            update: (store,{data: {EDIT_PROMISE_REQUESTS}}) => {
                const data = store.readQuery({query: FETCH_PROMISES_REQUESTS})
                context.commit('SET_PROMISES_REQUESTS',data)
                store.writeQuery({query: FETCH_PROMISES_REQUESTS, data})
            },
        }).then(res => {
            resolve(res)
        }).catch(error => {
            reject(error)
        })
    })
}

export async function exportPromisesRequest (context, filters) {
    return new Promise((resolve, reject) => {
        graphqlClient.mutate({
            mutation: EXPORT_PROMISE_REQUEST_FILE,
            variables: {
                project_id: filters.project,
                status: filters.status, 
            },
        }).then(res => {
            resolve(res)
        }).catch(error => {
            reject(error)
        })
    })
}

export async function exportPromisesOrders (context, filters) {
    return new Promise((resolve, reject) => {
        graphqlClient.mutate({
            mutation: EXPORT_PROMISE_ORDER_FILE,
            variables: {
                project_id: filters.project,
                status: filters.status, 
            },
        }).then(res => {
            resolve(res)
        }).catch(error => {
            reject(error)
        })
    })
}
