export default [
    {
        path: '/',
        name: 'login',
        component: () => import('@/views/auth/Login'),
        meta: {
            layout: 'full',
            requiresAuth: false,
        },
    },
    {
        path: '/forgot-password',
        name: 'forgot-password',
        component: () => import('@/views/auth/ForgotPassword'),
        meta: {
            layout: 'full',
            requiresAuth: false,
        }
    },
    {
        path: '/reset-password/:token/:email',
        name: 'reset-password',
        component: () => import('@/views/auth/ResetPassword'),
        meta: {
            layout: 'full',
            requiresAuth: false,
        }
    },
]
