export function SET_PROFILE (state, data) {
    state.profile = data.me
    state.permissions = data.me.permissions
    state.role = data.me.roles[0]
    state.status = true
}

export function DESTROY_PROFILE (state) {
    state.profile = {}
    state.role = {}
    state.permissions = []
    state.status = false
}

export function SET_REALSTATEAGENCIES (state, data) {
    state.realStateAgencies = data.realstateagencies.data
}

export function SET_REALSTATEAGENCIES_NO_PAGINATION (state, data) {
    state.realStateAgencies = data.realstateagenciesnopagination.data
}

export function SET_USERSREALSTATEAGENCIES (state, data) {
    state.usersRealStateAgencies = data.users.data
}

export function SET_PROJECTS (state, data) {
    state.profileProjects = data.projectsrelatedtome
}

export function SET_LOCATIONS (state, data) {
    state.locations = data.regions
}

export function SET_PROJECT_STATUS (state, data) {
    state.projectstatus = data.projectstatus
}

export function SET_SUBSIDY_TYPES (state, data) {
    state.subsidytypes = data.subsidytypes
}

export function SET_PLAN_ADITTIONAL_TYPES (state, data) {
    state.plansadditional = data.plansadditional
}

export function SET_PROJECT_STAGE_TYPES (state, data) {
    state.projectstage = data.projectstage
}