import {ApolloClient} from 'apollo-client'
import {ApolloLink} from 'apollo-link'
import {setContext} from 'apollo-link-context'
import {InMemoryCache} from 'apollo-cache-inmemory'
import {createUploadLink} from 'apollo-upload-client'
import {errorInterceptor} from '@/helpers/apolloInterceptors'
import store from '@/store'

const httpOptions = {
    uri: `${process.env.VUE_APP_URL_API}${process.env.VUE_APP_URL_GRAPHQL}`
}

const httpLink = createUploadLink({
    uri: `${process.env.VUE_APP_URL_API}${process.env.VUE_APP_URL_GRAPHQL}`
})

const authLink = setContext(async (_, { headers }) => {
    const token = store.state.auth.accessToken

    return {
        headers: {
            ...headers,
            authorization: token ? `Bearer ${token}` : '',
            Accept: 'application/json',
        }
    }
})

const onErrorLink = errorInterceptor

const cache = new InMemoryCache()

export default new ApolloClient({
    link: ApolloLink.from([onErrorLink,authLink,httpLink]),
    cache,
});
