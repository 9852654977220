import graphqlClient from '@/plugins/apollo'
import { FETCH_TAGS_NEWS } from '@/services/graphql/tagsNews';

export async function fetchTagsNews (context) {
    return new Promise((resolve, reject) => {
        graphqlClient.query({
            query: FETCH_TAGS_NEWS
        }).then(res => {
            context.commit('SET_TAGS_NEWS',res.data)
            resolve(res)
        }).catch(error => {
            reject(error)
        })
    })
}