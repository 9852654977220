export function SET_BUSINESS (state, data) {
    state.business = data 
}
export function SET_BUSINESS_PER_TYPE_CLEAN (state, data) {

    state.businesspertype.prospectspertypequery = {}; 
    state.businesspertype.prospectspertypequery = data.prospectspertypequery;
}
export function UPDATE_ONE_BUSINESS (state, data) {    //mezclamos datos con la vista de tarjetas 
    
    if(state.businesspertype)
    if(state.businesspertype.prospectspertypequery)
    if(state.businesspertype.prospectspertypequery.data){ 
        state.businesspertype.prospectspertypequery.data.forEach(element => { 
            if(element.id == data.data.editProspect.id){  
                state.businesspertype.prospectspertypequery.paginate_totals.forEach(elementPaginate => {
                    if(element.pipelinestage.id == elementPaginate.id){
                        elementPaginate.data = parseInt(elementPaginate.data)-1; 
                    }
                    if(data.data.editProspect.pipelinestage.id == elementPaginate.id){
                        elementPaginate.data = parseInt(elementPaginate.data)+1; 
                    }
                });
                element.pipelinestage = data.data.editProspect.pipelinestage; 
            }
        });
    }
}


export function SET_BUSINESS_PER_TYPE (state, data) { 
    if(state.businesspertype)
    if(state.businesspertype.prospectspertypequery)
    if(state.businesspertype.prospectspertypequery.data){

        var new_ones = [];
        data.prospectspertypequery.data.forEach(elementData =>{
            var exists = false;
            state.businesspertype.prospectspertypequery.data.forEach(elementPaginate =>{
                if(elementData.id == elementPaginate.id){
                    exists = true;
                }
            });
            if(!exists){
                new_ones.push(elementData);
            }
        }); 

        var new_ones_paginate = [];
        data.prospectspertypequery.paginate_totals.forEach(elementData =>{
            var exists = false;
            state.businesspertype.prospectspertypequery.paginate_totals.forEach(elementPaginate =>{
                if(elementData.id == elementPaginate.id){
                    exists = true;
                }
            });
            if(!exists){
                new_ones_paginate.push(elementData);
            }
        });

        data.prospectspertypequery.data = [].concat(state.businesspertype.prospectspertypequery.data, new_ones);
        data.prospectspertypequery.paginate_totals = [].concat(state.businesspertype.prospectspertypequery.paginate_totals, new_ones_paginate);
    }
    state.businesspertype = data; 

}

export function SET_PIPELINES (state, data) {
    state.pipelines = data
}

export function SET_FILTERS_BUSINESS(state, data) {
    state.filter_bussines = data
}

export function SET_FILTERS_BUSINESS_VIEW(state, data) { 
    state.filter_bussines_view = data
}

export function SET_FILTERS_RELOAD_VIEW(state, data) {
    state.filter_bussines_reload = data
}
