export function permissions (state) {
    return state.profile.permissions
}

export function projects (state) {
    return state.profileProjects
}

export function realStateAgencies (state) {
    return state.realStateAgencies
}

export function projectStatus (state) {
    return state.projectstatus
}

export function subsidyTypes (state) {
    return state.subsidytypes
}

export function plansAdditional (state) {
    return state.plansadditional
}

export function projectStage (state) {
    return state.projectstage
}