import {onError} from 'apollo-link-error'
import router from '../router'

export const errorInterceptor = onError(({ forward,graphQLErrors, networkError, operation}) => {
    if (graphQLErrors) {
        console.log(graphQLErrors)
        graphQLErrors.map(({ message }) =>
            console.log(`[GraphQL error]: Message: ${message}`),
        )
    }

    if (networkError) {
        switch (networkError.statusCode) {
            case 401:
                store.dispatch('auth/refreshToken')
                    .then(res => {
                        store.commit('auth/SET_TOKEN',res.data)
                        if(res.status === 200 || res.status === 201) {
                            return forward(operation)
                        }
                    }).catch(error => {
                        store.commit('auth/DESTROY_TOKEN')
                        router.push('/').catch(()=>{});
                    })
                break;
            case 403:
                router.push({ name: 'page-error-403' })
                break;
            case 404:
                router.push({ name: 'page-error-404' })
                break;
            default:
                console.log(networkError)
                break;
        }
    }
});
