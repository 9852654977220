import Vue from 'vue'
import store from '@/store'
import graphqlClient from '@/plugins/apollo'
import {
  FETCH_BOOKINGS_ORDERS,
  FETCH_BOOKINGS_REQUESTS,
  EDIT_BOOKINGS_REQUESTS,
  EDIT_BOOKINGS_ORDERS,
  EXPORT_BOOKING_REQUEST_FILE,
  EXPORT_BOOKING_ORDER_FILE
} from '@/services/graphql/bookings'

export async function fetchBookingsOrders (context, filter) {
  return new Promise((resolve, reject) => {
    graphqlClient
      .query({
        query: FETCH_BOOKINGS_ORDERS,
        variables: {
          project_id: filter.project ? filter.project.toString() : '',
          status: filter.status,
          page: filter.page
        }
      })
      .then(res => {
        context.commit('SET_BOOKINGS_ORDERS', res.data)
        resolve(res)
      })
      .catch(error => {
        reject(error)
      })
  })
}

export async function updateBookingOrder (context, bookingOrder) {
  return new Promise((resolve, reject) => {
    graphqlClient
      .mutate({
        mutation: EDIT_BOOKINGS_ORDERS,
        variables: {
          bookingorder_id: bookingOrder.id,
          status: bookingOrder.status
        },
        update: (store, { data: { EDIT_BOOKINGS_ORDERS } }) => {
          const data = store.readQuery({ query: FETCH_BOOKINGS_ORDERS })
          context.commit('SET_BOOKINGS_ORDERS', data)
          store.writeQuery({ query: FETCH_BOOKINGS_ORDERS, data })
        }
      })
      .then(res => {
        resolve(res)
      })
      .catch(error => {
        reject(error)
      })
  })
}

export async function fetchBookingsRequests (context, filter) {
  return new Promise((resolve, reject) => {
    graphqlClient
      .query({
        query: FETCH_BOOKINGS_REQUESTS,
        variables: {
          project_id: filter.project ? filter.project.toString() : '',
          status: filter.status,
          page: filter.page
        }
      })
      .then(res => {
        context.commit('SET_BOOKINGS_REQUESTS', res.data)
        resolve(res)
      })
      .catch(error => {
        reject(error)
      })
  })
}

export async function updateBookingRequest (context, bookingRequest) {
  return new Promise((resolve, reject) => {
    graphqlClient
      .mutate({
        mutation: EDIT_BOOKINGS_REQUESTS,
        variables: {
          bookingrequest_id: bookingRequest.id,
          status: bookingRequest.name
        },
        update: (store, { data: { EDIT_BOOKINGS_REQUESTS } }) => {
          const data = store.readQuery({ query: FETCH_BOOKINGS_REQUESTS })
          context.commit('SET_BOOKINGS_REQUESTS', data)
          store.writeQuery({ query: FETCH_BOOKINGS_REQUESTS, data })
        }
      })
      .then(res => {
        resolve(res)
      })
      .catch(error => {
        reject(error)
      })
  })
}
export async function exportBookingsRequest (context, filters) {
  return new Promise((resolve, reject) => {
    graphqlClient
      .mutate({
        mutation: EXPORT_BOOKING_REQUEST_FILE,
        variables: {
          project_id: filters.project ? filters.project : null,
          status: filters.status
        }
      })
      .then(res => {
        resolve(res)
      })
      .catch(error => {
        reject(error)
      })
  })
}

export async function exportBookingsOrders (context, filters) {
  return new Promise((resolve, reject) => {
    graphqlClient
      .mutate({
        mutation: EXPORT_BOOKING_ORDER_FILE,
        variables: {
          project_id: filters.project ? filters.project : null,
          status: filters.status
        }
      })
      .then(res => {
        resolve(res)
      })
      .catch(error => {
        reject(error)
      })
  })
}
