export function SET_CONTACTS (state, data) {
    state.contacts = data.contactform
}

export function SET_CONTACTS_NEWSLETTER (state, data) {
    state.contactsNewsletter = data.contactformnewsletter
}
 
export function SET_CONTACTS_ENTERPRISE (state, data) {
    state.contactsEnterprise = data.contactformenterprise
}

export function SET_CONTACTS_FORM_SECTION (state, data) {
    state.contactsFormSection = data.contactformsection
}

export function SET_CONTACTS_FORM_HOME_SECTION (state, data) {
    state.contactsFormHomeSection = data.contactformhomesection
}