import store from '@/store'

export default [
  {
    path: '/contacts',
    name: 'contacts',
    component: () => import('@/views/contacts/Contacts'),
    meta: {
      requiresAuth: true,
      navActiveLink: 'contacts',
      breadcrumb: [
        {
          text: 'Contactos',
          active: true
        }
      ]
    },
    beforeEnter (to, from, next) {
      const role = store.state.profile.role
      const status = store.state.profile.status

      if (
        !status ||
        (status &&
          (role.name === 'Admin' ||
            role.name === 'SuperAdmin' ||
            role.name === 'NewsUser' ||
            role.name === 'RealstateAdmin' ||
            role.name === 'RealstateVendor'))
      )
        next()
      else next({ name: 'error-403' })
    }
  },
  {
    path: '/contacts/newsletter',
    name: 'contacts-newsletter',
    component: () => import('@/views/contacts/ContactsNewsletter'),
    meta: {
      requiresAuth: true,
      navActiveLink: 'contacts/newsletter',
      breadcrumb: [
        {
          text: 'Contactos Newsletter',
          active: true
        }
      ]
    },
    beforeEnter (to, from, next) {
      const role = store.state.profile.role
      const status = store.state.profile.status

      if (
        !status ||
        (status &&
          (role.name === 'Admin' ||
            role.name === 'SuperAdmin' ||
            role.name === 'NewsUser'))
      )
        next()
      else next({ name: 'error-403' })
    }
  },
  {
    path: '/contacts/enterprise',
    name: 'contacts-enterprise',
    component: () => import('@/views/contacts/ContactsEnterprise'),
    meta: {
      requiresAuth: true,
      navActiveLink: 'contacts/enterprise',
      breadcrumb: [
        {
          text: 'Contactos Inmobiliaria',
          active: true
        }
      ]
    },
    beforeEnter (to, from, next) {
      const role = store.state.profile.role
      const status = store.state.profile.status

      if (
        !status ||
        (status &&
          (role.name === 'Admin' ||
            role.name === 'SuperAdmin' ||
            role.name === 'NewsUser'))
      )
        next()
      else next({ name: 'error-403' })
    }
  },
  {
    path: '/contacts/form-section',
    name: 'contacts-form-section',
    component: () => import('@/views/contacts/ContactsFormSection'),
    meta: {
      requiresAuth: true,
      navActiveLink: 'contacts/form-section',
      breadcrumb: [
        {
          text: 'Contactos Formulario',
          active: true
        }
      ]
    },
    beforeEnter (to, from, next) {
      const role = store.state.profile.role
      const status = store.state.profile.status

      if (
        !status ||
        (status &&
          (role.name === 'Admin' ||
            role.name === 'SuperAdmin' ||
            role.name === 'NewsUser'))
      )
        next()
      else next({ name: 'error-403' })
    }
  },
  {
    path: '/contacts/form-home-section',
    name: 'contacts-form-home-section',
    component: () => import('@/views/contacts/ContactsFormHomeSection'),
    meta: {
      requiresAuth: true,
      navActiveLink: 'contacts/form-home-section',
      breadcrumb: [
        {
          text: 'Contactos Formulario Home',
          active: true
        }
      ]
    },
    beforeEnter (to, from, next) {
      const role = store.state.profile.role
      const status = store.state.profile.status

      if (
        !status ||
        (status &&
          (role.name === 'Admin' ||
            role.name === 'SuperAdmin' ||
            role.name === 'NewsUser'))
      )
        next()
      else next({ name: 'error-403' })
    }
  }
]
