import gql from 'graphql-tag'

export const FETCH_USERS = gql`
    query fetchUsers($page: Int) {
        users(page: $page) {
            data{
                id
                name
                last_name
                email
                status
                permissions{
                    name
                }
                roles{
                    name
                }
                audits{
                    id
                    event
                    old_values
                    new_values
                    created_at
                    user{
                        name
                        last_name
                    }
                }
                projectuser{
                    id
                    project{
                        id
                    }
                }
                realstateagencyuser{
                    id
                    realstateagency{
                        id
                        name
                    }
                }

            }
            total
            per_page
            current_page
            from
            to
            last_page
            has_more_pages
        }
    }
`

export const CREATE_USERS = gql`
    mutation createUsers(
        $name: String!,
        $last_name: String!,
        $email: String!,
        $password: String!,
        $project_ids: String,
        $role: String!,
        $realstateagency_id: String
        ){
        createUser(
            name: $name,
            last_name: $last_name,
            email: $email,
            password: $password,
            role: $role,
            project_ids: $project_ids
            realstateagency_id: $realstateagency_id
        ) {
            id
            name
            last_name
            email
            status
            permissions{
                id
                name
            }
            roles{
                id
                name
            }
            audits{
                id
                event
                old_values
                new_values
                created_at
                user{
                    name
                    last_name
                }
            }
            realstateagencyuser{
                id
                realstateagency{
                    id
                    projects{
                        id
                        name
                    }
                }
            }
            projectuser{
                id
                project{
                name
                id
                }
            }
        }
    }
`

export const EDIT_USERS = gql`
    mutation EditUsers(
        $id: Int!
        $name: String!,
        $last_name: String!,
        $email: String!,
        $status: Int!,
        $role: String!,
        $projects_id: String,
        $permissions: String!,
        $realstateagency_id: String
        ) {
        editUser(
            id: $id
            name: $name,
            last_name: $last_name,
            email: $email,
            status: $status,
            role: $role
            realstateagency_id: $realstateagency_id
            projects_id: $projects_id
            permissions: $permissions) {
            id
            name
            last_name
            email
            status
            permissions{
                id
                name
            }
            roles{
                id
                name
            }
            audits{
                id
                event
                old_values
                new_values
                created_at
                user{
                    name
                    last_name
                }
            }
            realstateagencyuser{
                id
                realstateagency{
                    id
                    projects{
                        id
                        name
                    }
                }
            }
            projectuser{
                id
                project{
                name
                id
                }
            }
        }
    }
`

export const DELETE_USERS = gql`
    mutation deleteUser($id: Int!) {
        deleteUser(id: $id){
            id
            name
            last_name
            email
            status
            permissions{
                id
                name
            }
            roles{
                id
                name
            }
            audits{
                id
                event
                old_values
                new_values
                created_at
                user{
                    name
                    last_name
                }
            }
            realstateagencyuser{
                id
                realstateagency{
                    id
                    projects{
                        id
                        name
                    }
                }
            }
            projectuser{
                id
                project{
                name
                id
                }
            }
        }
    }
`

export const GET_NOTIFICATIONS = gql`
    query fetchNotications($page: Int,$user_id: Int,$limit: Int,) {
        notificationsusers(page: $page,user_id: $user_id,limit: $limit,) {
            data{
                id 
                notification{
                    id
                    title
                    message
                    url
                }
            } 
        }
    }
`

export const CHANGE_STATUS = gql`
    mutation changeUserStatus(
        $status: Boolean!,
        $userid: Int!) {
        changeUserStatus(
            status: $status,
            userid: $userid) {
            id
            name
            last_name
            email
            status
            permissions{
                name
            }
            roles{
                name
            }
            audits{
                id
                event
                old_values
                new_values
                created_at
                user{
                    name
                    last_name
                }
            }
        }
    }
`
