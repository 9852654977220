export function SET_COMMENTS (state, data) {
    state.comments = data.comments
}

export function ADD_ONE_COMMENTS (state, data) {
    var found = false;
    state.comments.data.forEach(element => {
        if(element.id == data.createComment.id){
            found = true;
        }
    });
    if(!found){
        state.comments.data.unshift(data.createComment);
    }
}

export function UPDATE_ONE_COMMENTS (state, data) {
    var found = false;
    state.comments.data.forEach(element => {
        if(element.id == data.editComment.id){
            found = true;
        }
    });
    if(!found){
        state.comments.data.unshift(data.editComment);
    }
}