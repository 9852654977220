export default {
  setPermissions (permissions) {
    var permissionsArray = []
    permissions.forEach(function (permission) {
      permissionsArray.push(permission.name)
    })
    localStorage.setItem(
      'permissionDataMenu',
      btoa(JSON.stringify(permissionsArray))
    )
  },
  setRole (roles) {
    localStorage.setItem('roleDataMenu', btoa(JSON.stringify(roles)))
  },
  detroySession () {
    localStorage.removeItem('permissionDataMenu')
    localStorage.removeItem('roleDataMenu')
  },
  getPermissions () {
    return JSON.parse(atob(localStorage.getItem('permissionDataMenu')))
  },
  getRole () {
    var role = JSON.parse(atob(localStorage.getItem('roleDataMenu')))
    if (role[0]) if (role[0].name) return role[0].name
    return role
  },
  showSectionMenu (route_name) {
    var role = this.getRole()
    var permissions_related = {
      home: 'generals',
      business: 'show-business',
      preapprovals: 'show-preapprovals',
      Usuarios: 'show-users',
      Proyectos: 'show-projects',
      Inmobiliarias: 'show-agencies',
      Ventas: 'show-quotations',
      Reservas: 'show-quotations',
      Promesas: 'show-quotations',
      //usuarios
      users: 'create-user',
      users: 'show-user',
      users: 'show-users',
      roles: 'show-roles',
      permissions: 'show-permissions',
      projects: 'show-projects',
      properties: 'show-properties',
      quotations: 'show-quotations',
      'bookings-requests': 'show-bookingrequest',
      'bookings-orders': 'show-bookingorder',
      'promises-requests': 'show-promise-requests',
      'promises-orders': 'show-promise-orders',
      'agencies-create': 'create-agency',
      agencies: 'show-agencies',
      news: 'news',
      news: 'see-news',
      Contactos: 'show-contacts',
      contacts: 'show-contacts',
      'contacts-newsletter': 'show-contacts-newsletter',
      'contacts-enterprise': 'show-contacts-enterprise',
      'contacts-form-section': 'show-contacts-form-section',
      'contacts-form-home-section': 'show-contacts-form-home-section',
      Planes: 'generals',
      'plans-create': 'generals',
      'plans-list': 'generals',
      'plans-contract': 'generals',
      comments: 'show-comments'
    }
    var global_permissions = JSON.parse(
      atob(localStorage.getItem('permissionDataMenu'))
    )
    var returnme = '0'

    Object.entries(permissions_related).forEach(([key, value]) => {
      if (route_name == key) {
        if (global_permissions.indexOf(value) !== -1) {
          if (
            role == 'PreapprovalUser' &&
            (key == 'projects' || key == 'Proyectos')
          ) {
            returnme = '0'
          } else {
            returnme = '1'
          }
          //fix para mostrar preaprobados con cuenta de realstateadmin y user
          if (
            (role == 'RealstateAdmin' || role == 'RealstateUser' || role == 'RealstateVendor') &&
            key == 'preapprovals'
          ) {
            returnme = '1'
          }
          if (role == 'NewsUser' && route_name == 'news') {
            returnme = '1'
          }
          if (role == 'Admin' || role == 'SuperAdmin') {
            returnme = '1'
          }
        } else {
          if (
            (role == 'SuperAdmin' || role == 'Admin') &&
            route_name == 'news'
          ) {
            returnme = '1'
          }
          if (
            (role == 'SuperAdmin' || role == 'Admin') &&
            route_name == 'contacts'
          ) {
            returnme = '1'
          }
        }
      }
    })
    return returnme
  },

  checkPermissions (permissions) {
    var global_permissions = JSON.parse(
      atob(localStorage.getItem('permissionDataMenu'))
    )
    var role = this.getRole()
    Object.entries(permissions).forEach(([key, value]) => {
      var name_underscore = key.replace(/_/g, '-')
      if (global_permissions.indexOf(name_underscore) !== -1) {
        permissions[key] = true
      }
      // if(role == "Admin" || role == "SuperAdmin"){
      //     permissions[key] = true;
      // }
    })
    return permissions
  }
}
