import gql from 'graphql-tag'

export const FETCH_PREAPPROVALS = gql`
    query fetchPreapprovals (
        $project_id: Int,
        $status: String,
        $page: Int
    ){
        preapprovals(
            project_id: $project_id,
            status: $status,
            page: $page
        ){
            data{
                id
                status
                document_url
                description
                property_type
                amount_property
                amount_foot
                credit_term
                subsidy_type_id
                created_at
                project{
                    id
                    name
                    commune{
                        id
                        name
                    }
                }
                customer{
                    id
                    rut
                    rut_dv
                    name
                    last_name
                    email
                    phone
                    nationality
                    civil_status
                    worker_type
                    labor_old
                    monthly_rent
                    commune{
                        id
                        name
                    }
                    codeudor{
                        id
                        name
                        rut
                        phone
                        email
                        worker_type
                        labor_old
                        monthly_rent
                        civil_status
                        nationality
                        codeudor_relation
                    }
                }
            }
            total
            per_page
            current_page
            from
            to
            last_page
            has_more_pages
        }
    }
`

export const EDIT_PREAPPROVALS = gql`
    mutation editPreapproval (
        $preapproval_id: Int!
        $status: String!,
        $description: String!,
        $file: Upload,
        ) {
        editPreapproval(
            preapproval_id: $preapproval_id,
            status: $status,
            description: $description,
            file: $file,
        ) {
            id
            status
            document_url
            description
            property_type
            amount_property
            amount_foot
            credit_term
            subsidy_type_id
            created_at
            project{
                id
                name
                commune{
                    id
                    name
                }
            }
            customer{
                id
                rut
                rut_dv
                name
                last_name
                email
                phone
                nationality
                civil_status
                worker_type
                labor_old
                monthly_rent
                commune{
                    id
                    name
                }
                codeudor{
                    id
                    name
                    rut
                    phone
                    email
                    worker_type
                    labor_old
                    monthly_rent
                    civil_status
                    nationality
                    codeudor_relation
                }
            }
        }
    }
`

export const EXPORT_PREAPPROVALS = gql`
    mutation exportPreapprovals(
        $project_id: Int,
        $status: String,
        ) {
        requestApprovalFile(
            project_id: $project_id,
            status: $status,
        ) {
            id
        }
    }
`