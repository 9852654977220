import Vue from 'vue';

export async function sendForgotPassword (context, password) {
    try {
        const {data} = await Vue.axios({
            method: 'POST',
            url: 'api/public/passwordForgetUser',
            data: password,
        })
        context.commit('forgotPassword',data)
    } catch (e) {
        context.commit('forgotPassword',e.response)
    }
}

export async function sendResetPassword(context, password) {
    try {
        const {data} = await Vue.axios({
            method: 'POST',
            url: 'api/public/passwordForgetChangeUser',
            data: password,
        })
        context.commit('resetPassword',data)
    } catch (e) {
        context.commit('resetPassword',e.response)
    }
}