import store from '@/store'

export default [
  {
    path: '/quotations',
    name: 'quotations',
    component: () => import('@/views/quotations/Quotations'),
    meta: {
      requiresAuth: true,
      navActiveLink: 'quotations',
      breadcrumb: [
        {
          text: 'Cotizaciones',
          active: true
        }
      ]
    },
    beforeEnter (to, from, next) {
      const role = store.state.profile.role
      const status = store.state.profile.status

      if (
        !status ||
        (status &&
          (role.name === 'Admin' ||
            role.name === 'RealstateAdmin' || 
            role.name === 'RealstateVendor' || 
            role.name === 'RealstateUser'))
      )
        next()
      else next({ name: 'error-403' })
    }
  },
  {
    path: '/quotations/:id/details',
    name: 'quotations-details',
    props: true,
    component: () => import('@/views/quotations/QuotationsDetails'),
    meta: {
      requiresAuth: true,
      navActiveLink: 'quotations',
      breadcrumb: [
        {
          text: 'Cotizaciones',
          to: '/quotations'
        },
        {
          text: 'Detalle Cotización',
          active: true
        }
      ]
    },
    beforeEnter (to, from, next) {
      const role = store.state.profile.role
      if (
        role.name === 'Admin' ||
        role.name === 'RealstateAdmin' ||
        role.name === 'RealstateVendor' ||
        role.name === 'RealstateUser'
      )
        next()
      else next({ name: 'error-403' })
    }
  },
  {
    path: '/quotations/:id/audits',
    name: 'quotations-audits',
    props: true,
    component: () => import('@/views/quotations/QuotationsAudits'),
    meta: {
      requiresAuth: true,
      navActiveLink: 'quotations',
      breadcrumb: [
        {
          text: 'Cotizaciones',
          to: '/quotations'
        },
        {
          text: 'Auditoria',
          active: true
        }
      ]
    },
    beforeEnter (to, from, next) {
      const role = store.state.profile.role
      if (role.name === 'Admin' || role.name === 'RealstateAdmin' || role.name === 'RealstateVendor') next()
      else next({ name: 'error-403' })
    }
  },
  {
    path: '/bookings-requests',
    name: 'bookings-requests',
    component: () => import('@/views/bookings/BookingsRequests'),
    meta: {
      requiresAuth: true,
      navActiveLink: 'bookings',
      breadcrumb: [
        {
          text: 'Solicitud de Reservas',
          active: true
        }
      ]
    },
    beforeEnter (to, from, next) {
      const role = store.state.profile.role
      const status = store.state.profile.status

      if (
        !status ||
        (status &&
          (role.name === 'Admin' ||
            role.name === 'RealstateAdmin' ||
            role.name === 'RealstateVendor' ||
            role.name === 'RealstateUser'))
      )
        next()
      else next({ name: 'error-403' })
    }
  },
  {
    path: '/bookings-requests/:id/details',
    name: 'bookings-requests-details',
    props: true,
    component: () => import('@/views/bookings/BookingsRequestsDetails'),
    meta: {
      requiresAuth: true,
      navActiveLink: 'bookings',
      breadcrumb: [
        {
          text: 'Solicitud de Reservas',
          to: '/bookings-requests'
        },
        {
          text: 'Detalles',
          active: true
        }
      ]
    },
    beforeEnter (to, from, next) {
      const role = store.state.profile.role
      if (
        role.name === 'Admin' ||
        role.name === 'RealstateAdmin' ||
        role.name === 'RealstateVendor' ||
        role.name === 'RealstateUser'
      )
        next()
      else next({ name: 'error-403' })
    }
  },
  {
    path: '/bookings-orders',
    name: 'bookings-orders',
    component: () => import('@/views/bookings/BookingsOrders'),
    meta: {
      requiresAuth: true,
      navActiveLink: 'bookings',
      breadcrumb: [
        {
          text: 'Pagos de Reservas',
          active: true
        }
      ]
    },
    beforeEnter (to, from, next) {
      const role = store.state.profile.role
      const status = store.state.profile.status

      if (
        !status ||
        (status &&
          (role.name === 'Admin' ||
            role.name === 'RealstateAdmin' ||
            role.name === 'RealstateVendor' ||
            role.name === 'RealstateUser'))
      )
        next()
      else next({ name: 'error-403' })
    }
  },
  {
    path: '/bookings-orders/:id/details',
    name: 'bookings-orders-details',
    props: true,
    component: () => import('@/views/bookings/BookingsOrdersDetails'),
    meta: {
      requiresAuth: true,
      navActiveLink: 'bookings',
      breadcrumb: [
        {
          text: 'Pagos de Reservas',
          to: '/bookings-orders'
        },
        {
          text: 'Detalles',
          active: true
        }
      ]
    },
    beforeEnter (to, from, next) {
      const role = store.state.profile.role
      if (
        role.name === 'Admin' ||
        role.name === 'RealstateAdmin' ||
        role.name === 'RealstateVendor' ||
        role.name === 'RealstateUser'
      )
        next()
      else next({ name: 'error-403' })
    }
  },
  {
    path: '/promises-requests',
    name: 'promises-requests',
    component: () => import('@/views/promises/PromisesRequests'),
    meta: {
      requiresAuth: true,
      navActiveLink: 'promises',
      breadcrumb: [
        {
          text: 'Solicitud de Promesas',
          active: true
        }
      ]
    },
    beforeEnter (to, from, next) {
      const role = store.state.profile.role
      if (
        role.name === 'Admin' ||
        role.name === 'RealstateAdmin' ||
        role.name === 'RealstateVendor' ||
        role.name === 'RealstateUser'
      )
        next()
      else next({ name: 'error-403' })
    }
  },
  {
    path: '/promises-requests/:id/details',
    name: 'promises-requests-details',
    props: true,
    component: () => import('@/views/promises/PromisesRequestsDetails'),
    meta: {
      requiresAuth: true,
      navActiveLink: 'promises',
      breadcrumb: [
        {
          text: 'Solicitud de Promesas',
          to: '/promises-requests'
        },
        {
          text: 'Detalles',
          active: true
        }
      ]
    },
    beforeEnter (to, from, next) {
      const role = store.state.profile.role
      if (
        role.name === 'Admin' ||
        role.name === 'RealstateAdmin' ||
        role.name === 'RealstateVendor' ||
        role.name === 'RealstateUser'
      )
        next()
      else next({ name: 'error-403' })
    }
  },
  {
    path: '/promises-orders',
    name: 'promises-orders',
    component: () => import('@/views/promises/PromisesOrders'),
    meta: {
      requiresAuth: true,
      navActiveLink: 'promises',
      breadcrumb: [
        {
          text: 'Pagos de Promesas',
          active: true
        }
      ]
    },
    beforeEnter (to, from, next) {
      const role = store.state.profile.role
      if (
        role.name === 'Admin' ||
        role.name === 'RealstateAdmin' ||
        role.name === 'RealstateVendor' ||
        role.name === 'RealstateUser'
      )
        next()
      else next({ name: 'error-403' })
    }
  },
  {
    path: '/promises-orders/:id/details',
    name: 'promises-orders-details',
    props: true,
    component: () => import('@/views/promises/PromisesOrdersDetails'),
    meta: {
      requiresAuth: true,
      navActiveLink: 'promises',
      breadcrumb: [
        {
          text: 'Pagos de Promesas',
          to: '/promises-orders'
        },
        {
          text: 'Detalles',
          active: true
        }
      ]
    },
    beforeEnter (to, from, next) {
      const role = store.state.profile.role
      if (
        role.name === 'Admin' ||
        role.name === 'RealstateAdmin' ||
        role.name === 'RealstateVendor' ||
        role.name === 'RealstateUser'
      )
        next()
      else next({ name: 'error-403' })
    }
  }
]
