import gql from 'graphql-tag'

export const FETCH_ROLES = gql`
    query fetchRoles{
        roles{
            id
            name
            permissions{
                id
                name
            }
            audits{
                id
                event
                old_values
                new_values
                created_at
                user{
                    name
                    last_name
                }
            }
        }
    }
`

export const CREATE_ROLES = gql`
    mutation createRoles($name: String!) {
        createRole(name: $name) {
            id
            name
            permissions{
                id
                name
            }
            audits{
                id
                event
                old_values
                new_values
                created_at
                user{
                    name
                    last_name
                }
            }
        }
    }
`

export const EDIT_ROLES = gql`
    mutation EditRoles($id: Int!, $name: String!) {
        editRole(id: $id, name: $name) {
            id
            name
            permissions{
                id
                name
            }
            audits{
                id
                event
                old_values
                new_values
                created_at
                user{
                    name
                    last_name
                }
            }
        }
    }
`

export const DELETE_ROLES = gql`
    mutation deleteRole($name: String!) {
        deleteRole(name: $name){
            id
            name
        }
    }
`
