import store from '@/store'

export default [
    {
        path: '/users',
        name: 'users',
        component: () => import('@/views/users/Users'),
        meta: {
            requiresAuth: true,
            navActiveLink: 'users',
            breadcrumb: [
                {
                    text: 'Usuarios',
                    active: true,
                },
            ],
        },
        beforeEnter(to, from, next) {
            const role = store.state.profile.role
            if (role.name === 'Admin' || role.name === 'RealstateAdmin' || role.name === 'RealstateVendor') next()
            else next({ name: 'error-403' })
        },
    },
    {
        path: '/users/create',
        name: 'users-create',
        component: () => import('@/views/users/UsersCreate'),
        meta: {
            requiresAuth: true,
            navActiveLink: 'users',
            breadcrumb: [
                {
                    text: 'Usuarios',
                    to: '/users'
                },
                {
                    text: 'Nuevo Usuario',
                    active: true,
                },
            ],
        },
        beforeEnter(to, from, next) {
            const role = store.state.profile.role
            if (role.name === 'Admin' || role.name === 'RealstateAdmin' || role.name === 'RealstateVendor') next()
            else next({ name: 'error-403' })
        },
    },
    {
        path: '/users/:id/edit',
        name: 'users-edit',
        props: true,
        component: () => import('@/views/users/UsersEdit'),
        meta: {
            requiresAuth: true,
            navActiveLink: 'users',
            breadcrumb: [
                {
                    text: 'Usuarios',
                    to: '/users'
                },
                {
                    text: 'Editar Usuario',
                    active: true,
                },
            ],
        },
        beforeEnter(to, from, next) {
            const role = store.state.profile.role
            if (role.name === 'Admin' || role.name === 'RealstateAdmin' || role.name === 'RealstateVendor') next()
            else next({ name: 'error-403' })
        },
    },
    {
        path: '/users/:id/audits',
        name: 'users-audits',
        props: true,
        component: () => import('@/views/users/UsersAudits'),
        meta: {
            requiresAuth: true,
            navActiveLink: 'users',
            breadcrumb: [
                {
                    text: 'Usuarios',
                    to: '/users'
                },
                {
                    text: 'Auditoria',
                    active: true,
                },
            ],
        },
        beforeEnter(to, from, next) {
            const role = store.state.profile.role
            if (role.name === 'Admin' || role.name === 'RealstateAdmin' || role.name === 'RealstateVendor') next()
            else next({ name: 'error-403' })
        },
    },
    {
        path: '/permissions',
        name: 'permissions',
        component: () => import('@/views/permissions/Permissions'),
        meta: {
            requiresAuth: true,
            navActiveLink: 'permissions',
            breadcrumb: [
                {
                    text: 'Usuarios',
                    to: '/users'
                },
                {
                    text: 'Permisos',
                    active: true,
                },
            ],
        },
        beforeEnter(to, from, next) {
            const role = store.state.profile.role
            if (role.name === 'Admin') next()
            else next({ name: 'error-403' })
        },
    },
    {
        path: '/permissions/:id/audits',
        name: 'permissions-audits',
        props: true,
        component: () => import('@/views/permissions/PermissionsAudits'),
        meta: {
            requiresAuth: true,
            navActiveLink: 'permissions',
            breadcrumb: [
                {
                    text: 'Usuarios',
                    to: '/users'
                },
                {
                    text: 'Permisos',
                    to: '/permissions'
                },
                {
                    text: 'Auditoria',
                    active: true,
                },
            ],
        },
        beforeEnter(to, from, next) {
            const role = store.state.profile.role
            if (role.name === 'Admin') next()
            else next({ name: 'error-403' })
        },
    },
    {
        path: '/roles',
        name: 'roles',
        component: () => import('@/views/roles/Roles'),
        meta: {
            requiresAuth: true,
            navActiveLink: 'roles',
            breadcrumb: [
                {
                    text: 'Usuarios',
                    to: '/users'
                },
                {
                    text: 'Roles',
                    active: true,
                },
            ],
        },
        beforeEnter(to, from, next) {
            const role = store.state.profile.role
            if (role.name === 'Admin') next()
            else next({ name: 'error-403' })
        },
    },
    {
        path: '/roles/:id/audits',
        name: 'roles-audits',
        props: true,
        component: () => import('@/views/roles/RolesAudits'),
        meta: {
            requiresAuth: true,
            navActiveLink: 'roles',
            breadcrumb: [
                {
                    text: 'Usuarios',
                    to: '/users'
                },
                {
                    text: 'Roles',
                    to: '/roles'
                },
                {
                    text: 'Auditoria',
                    active: true,
                },
            ],
        },
        beforeEnter(to, from, next) {
            const role = store.state.profile.role
            if (role.name === 'Admin') next()
            else next({ name: 'error-403' })
        },
    },
]
