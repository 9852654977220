import gql from 'graphql-tag'

export const FETCH_AGENCIES = gql`
  query fetchAgencies($page: Int, $id: Int) {
    realstateagencies(page: $page, id: $id) {
      data {
        id
        name
        official_name
        address
        phone
        email
        representative
        url_site
        visible
        description
        important
        orden
        medias {
          id
          mime
          full_url
          extension
          mediatype {
            id
            name
          }
        }
        audits {
          id
          event
          old_values
          new_values
          created_at
          user {
            name
            last_name
          }
        }
        plansrestrictions {
          id
          quantity
        }
      }
      total
      per_page
      current_page
      from
      to
      last_page
      has_more_pages
    }
  }
`

export const CREATE_AGENCIES = gql`
  mutation createAgencies(
    $name: String!
    $official_name: String
    $representative: String
    $address: String
    $phone: String
    $email: String
    $description: String
    $logo: Upload
    $cover_page: Upload
    $orden: String
    $url_site: String
    $restriction_quantity: Int
  ) {
    createRealStateAgency(
      name: $name
      official_name: $official_name
      representative: $representative
      address: $address
      phone: $phone
      email: $email
      description: $description
      logo: $logo
      cover_page: $cover_page
      orden: $orden
      url_site: $url_site
      restriction_quantity: $restriction_quantity
    ) {
      id
      name
      official_name
      address
      phone
      email
      representative
      url_site
      visible
      description
      important
      orden
      medias {
        id
        mime
        full_url
        extension
        mediatype {
          id
          name
        }
      }
      audits {
        id
        event
        old_values
        new_values
        created_at
        user {
          name
          last_name
        }
      }
      plansrestrictions {
        id
        quantity
      }
    }
  }
`

export const EDIT_AGENCIES = gql`
  mutation editAgencies(
    $agency_id: Int!
    $name: String!
    $official_name: String!
    $representative: String
    $address: String
    $phone: String
    $email: String
    $url_site: String
    $description: String
    $logo: Upload
    $cover_page: Upload
    $orden: String
    $restriction_quantity: Int
  ) {
    editRealStateAgency(
      agency_id: $agency_id
      name: $name
      official_name: $official_name
      representative: $representative
      address: $address
      phone: $phone
      email: $email
      url_site: $url_site
      description: $description
      logo: $logo
      cover_page: $cover_page
      orden: $orden
      restriction_quantity: $restriction_quantity
    ) {
      id
      name
      official_name
      address
      phone
      email
      representative
      url_site
      visible
      description
      important
      orden
      medias {
        id
        mime
        full_url
        extension
        mediatype {
          id
          name
        }
      }
      audits {
        id
        event
        old_values
        new_values
        created_at
        user {
          name
          last_name
        }
      }
      plansrestrictions {
        id
        quantity
      }
    }
  }
`

export const CHANGE_AGENCIES = gql`
  mutation deleteAgencies($id: Int!, $visibility: Boolean!) {
    deleteAgency(id: $id, visibility: $visibility) {
      id
      name
      official_name
      address
      phone
      email
      representative
      url_site
      visible
      description
      important
      medias {
        id
        mime
        full_url
        extension
        mediatype {
          id
          name
        }
      }
      audits {
        id
        event
        old_values
        new_values
        created_at
        user {
          name
          last_name
        }
      }
    }
  }
`

export const EDIT_REALSTATEAGENCY_IMPORTANT = gql`
  mutation editRealstateAgencyImportant($agency_id: Int!, $important: String) {
    editRealstateAgencyImportant(agency_id: $agency_id, important: $important) {
      id
      name
      official_name
      address
      phone
      email
      representative
      url_site
      visible
      description
      important
      medias {
        id
        mime
        full_url
        extension
        mediatype {
          id
          name
        }
      }
      audits {
        id
        event
        old_values
        new_values
        created_at
        user {
          name
          last_name
        }
      }
    }
  }
`
