import store from "@/store";

export default [
    {
        path: '/agencies',
        name: 'agencies',
        component: () => import('@/views/agencies/Agencies'),
        meta: {
            requiresAuth: true,
            navActiveLink: 'agencies',
            breadcrumb: [
                {
                    text: 'Inmobiliarias',
                    active: true,
                },
            ],
        },
        beforeEnter(to, from, next) {
            const role = store.state.profile.role
            if (role.name === 'Admin') next()
            else next({ name: 'error-403' })
        },
    },
    {
        path: '/agencies/create',
        name: 'agencies-create',
        component: () => import('@/views/agencies/AgenciesCreate'),
        meta: {
            requiresAuth: true,
            navActiveLink: 'agencies/create',
            breadcrumb: [
                {
                    text: 'Inmobiliarias',
                    to: '/agencies'
                },
                {
                    text: 'Nuevo Inmobiliaria',
                    active: true,
                },
            ],
        },
        beforeEnter(to, from, next) {
            const role = store.state.profile.role
            if (role.name === 'Admin') next()
            else next({ name: 'error-403' })
        },
    },
    {
        path: '/agencies/:id/edit',
        name: 'agencies-edit',
        props: true,
        component: () => import('@/views/agencies/AgenciesEdit'),
        meta: {
            requiresAuth: true,
            navActiveLink: 'agencies',
            breadcrumb: [
                {
                    text: 'Inmobiliarias',
                    to: '/agencies'
                },
                {
                    text: 'Editar Inmobiliaria',
                    active: true,
                },
            ],
        },
        beforeEnter(to, from, next) {
            const role = store.state.profile.role
            if (role.name === 'Admin') next()
            else next({ name: 'error-403' })
        },
    },
    {
        path: '/agencies/:id/audits',
        name: 'agencies-audits',
        props: true,
        component: () => import('@/views/agencies/AgenciesAudits'),
        meta: {
            requiresAuth: true,
            navActiveLink: 'users',
            breadcrumb: [
                {
                    text: 'Inmobiliarias',
                    to: '/agencies'
                },
                {
                    text: 'Auditoria',
                    active: true,
                },
            ],
        },
        beforeEnter(to, from, next) {
            const role = store.state.profile.role
            if (role.name === 'Admin') next()
            else next({ name: 'error-403' })
        },
    },
]
