import graphqlClient from '@/plugins/apollo'
import {FETCH_ROLES,CREATE_ROLES,EDIT_ROLES,DELETE_ROLES} from '@/services/graphql/roles'

export async function fetchRoles (context) {
    return new Promise((resolve, reject) => {
        graphqlClient.query({
            query: FETCH_ROLES
        }).then(res => {
            context.commit('SET_ROLES',res.data)
            resolve(res)
        }).catch(error => {
            reject(error)
        })
    })
}

export async function createRoles (context, role) {
    return new Promise((resolve, reject) => {
        graphqlClient.mutate({
            mutation: CREATE_ROLES,
            variables: {
                name: role.name
            },
            update: (store,{data: {createRole}}) => {
                const data = store.readQuery({query: FETCH_ROLES})
                data.roles.push(createRole)
                store.writeQuery({query: FETCH_ROLES, data})
            },
        }).then(res => {
            resolve(res)
        }).catch(error => {
            reject(error)
        })
    })
}

export async function updateRoles (context, role) {
    return new Promise((resolve, reject) => {
        graphqlClient.mutate({
            mutation: EDIT_ROLES,
            variables: {
                id: role.id,
                name: role.name,
            },
            update: (store,{data: {EDIT_ROLES}}) => {
                const data = store.readQuery({query: FETCH_ROLES})
                context.commit('SET_ROLES',data)
                store.writeQuery({query: FETCH_ROLES, data})
            },
        }).then(res => {
            resolve(res)
        }).catch(error => {
            reject(error)
        })
    })
}

export async function deleteRoles (context, role) {
    return new Promise((resolve, reject) => {
        graphqlClient.mutate({
            mutation: DELETE_ROLES,
            variables: {
                name: role.name
            },
            update: (store,{data: {DELETE_ROLES}}) => {
                const data = store.readQuery({query: FETCH_ROLES})
                data.roles.forEach(function(rol, index, object) {
                    if(rol.name === role.name) {
                        object.splice(index, 1)
                    }
                })
                store.writeQuery({query: FETCH_ROLES, data})
            },
        }).then(res => {
            resolve(res)
        }).catch(error => {
            reject(error)
        })
    })
}
