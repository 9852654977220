export default [
    {
        path: '/profile',
        name: 'profile',
        component: () => import('@/views/profile/Profile'),
        meta: {
            requiresAuth: true,
            navActiveLink: 'profile',
            breadcrumb: [
                {
                    text: 'Inicio',
                    to: '/home'
                },
                {
                    text: 'Perfil de usuario',
                    active: true,
                },
            ],
        },
    },
    {
        path: '/error-404',
        name: 'error-404',
        component: () => import('@/views/errors/Error404.vue'),
        meta: {
            layout: 'full',
            resource: 'Auth',
            requiresAuth: false,
        },
    },
    {
        path: '/error-401',
        name: 'error-401',
        component: () => import('@/views/errors/Error401.vue'),
        meta: {
            layout: 'full',
            resource: 'Auth',
            requiresAuth: false,
        },
    },
    {
        path: '/error-403',
        name: 'error-403',
        component: () => import('@/views/errors/Error403.vue'),
        meta: {
            layout: 'full',
            resource: 'Auth',
            requiresAuth: false,
        },
    },
]