export function SET_AGENCIES (state, data) {
    state.agencies = data.realstateagencies
}

export function UPDATE_ONE_AGENCIES (state, data) { 
    var found = false;
    state.agencies.data.forEach(element => {  
        if(element.id == data.editRealStateAgency.id){   
            element.name = data.editRealStateAgency.name; 
            element.description = data.editRealStateAgency.description;
            element.address = data.editRealStateAgency.address;
            element.official_name = data.editRealStateAgency.official_name;
            element.official = data.editRealStateAgency.official_name;
            element.orden = data.editRealStateAgency.orden;
            element.phone = data.editRealStateAgency.phone;
            element.contact = data.editRealStateAgency.contact;
            element.email = data.editRealStateAgency.email;
            element.url_site = data.editRealStateAgency.url_site; 
            element.orden = data.editRealStateAgency.orden;
            element.plansrestrictions = data.editRealStateAgency.plansrestrictions; 
            element.medias = data.editRealStateAgency.medias; 

            found = true;
        }
    });
    if(!found){
        state.agencies.data.unshift(data.editRealStateAgency);
    }
}

export function ADD_ONE_AGENCIES (state, data) { 
    var found = false;
    if(state.agencies){
        if(state.agencies.data){
            state.agencies.data.forEach(element => {
                if(element.id == data.createRealStateAgency.id){
                    found = true;
                }
            });
            if(!found){ 
                state.agencies.data.unshift(data.createRealStateAgency);
            }
        }
    }
}